import React from 'react';
import {FormControlLabel, Checkbox, Typography} from '@mui/material';
import './TreeVisualization.css';

interface BlazonTreeViewProps {
    content: string;
    showTree: boolean;
    setShowTree: React.Dispatch<boolean>
}

/**
 * Universal component for visualization blazon tree processed text
 * @param content blazon tree output from CBlazon
 */
const BlazonTreeView: React.FC<BlazonTreeViewProps> = ({ content,  showTree, setShowTree}) => {

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowTree(event.target.checked);
    };

    return (
        <div className="part">
            <form>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="show-tree"
                            checked={showTree}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label={
                        <Typography variant="body1" style={{ padding: 0 }}>
                            Show tree
                        </Typography>
                    }
                    labelPlacement="end"
                    sx={{textAlign: "center"}}
                />
            </form>
            <div id="tree-view" className={showTree ? "tree-view" : ""} dangerouslySetInnerHTML={{__html: content}}/>
        </div>
    );
}

export default BlazonTreeView;
