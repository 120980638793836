import {CBlazonField, CBlazonJson, CBlazonObject, CBlazonShield, CBlazonSplit} from "./cblazon_types";

function isValidCBlazonJson(json: any): json is CBlazonJson {
    return (
        typeof json === 'object' &&
        json !== null &&
        isValidCBlazonShield(json.shield) &&
        'bearer' in json &&
        'helmet' in json &&
        'valid' in json &&
        typeof json.valid === 'boolean'
    );
}

function isValidCBlazonShield(shield: any): shield is CBlazonShield {
    return (
        typeof shield === 'object' &&
        shield !== null &&
        isValidCBlazonSplit(shield.splits) &&
        Array.isArray(shield.fields) &&
        shield.fields.every((field: any) => isValidCBlazonField(field))
    );
}

function isValidCBlazonSplit(split: any): split is CBlazonSplit {
    return (
        typeof split === 'object' &&
        split !== null &&
        typeof split.type === 'string' &&
        ['VERTICAL', 'HORIZONTAL', 'UNSPLIT'].includes(split.type) &&
        typeof split.style === 'string' &&
        typeof split.where === 'string' &&
        split.where === 'CENTRE' &&
        typeof split.place === 'string' &&
        ['root', 'right', 'left', 'upper', 'lower'].includes(split.place) &&
        (split.field_ix === undefined || typeof split.field_ix === 'string') &&
        (split.splits === undefined || (Array.isArray(split.splits) && split.splits.every((s: any) => isValidCBlazonSplit(s))))
    );
}

function isValidCBlazonField(field: any): field is CBlazonField {
    return (
        typeof field === 'object' &&
        field !== null &&
        typeof field.colour === 'string' &&
        Array.isArray(field.objects) &&
        field.objects.every((obj: any) => isValidCBlazonObject(obj))
    );
}

function isValidCBlazonObject(obj: any): obj is CBlazonObject {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        Array.isArray(obj.name) &&
        obj.name.every((n: any) => typeof n === 'string') &&
        typeof obj.quantity === 'number' &&
        Array.isArray(obj.tag_attrs) &&
        obj.tag_attrs.every((ta: any) => typeof ta === 'string') &&
        Array.isArray(obj.obj_attrs) &&
        obj.obj_attrs.every((oa: any) => isValidCBlazonObject(oa)) &&
        typeof obj.natural_colours === 'boolean'
    );
}

export { isValidCBlazonJson };
