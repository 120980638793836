import split from "./split";
import * as d3 from "d3";
import {arraysHaveSameContent} from "./utils/ObjectUtils";
import {
    BlazonSpecificJson,
    FieldSpecific,
    FigureType,
    ObjectFigureGeneralSpecific,
    ObjectFigureHeroldSpecific,
    ObjectSpecific, SplitSpecific
} from "./types/types_blazonSpecific";
import {generateUniqueId, preprocessSvg} from "./utils/SvgUtils";
import {heIL} from "@mui/material/locale";

const PADDING = 8;

/**
 * Recursively draws SVG elements based on the provided parent object.
 * @param parent The parent object containing child elements.
 * @param svg The SVG container to draw the elements in.
 */
async function dfsDrawToSvg(parent: ObjectSpecific | FieldSpecific, svg: any) {
    if (!parent) return; // Handle null or undefined parent

    let parentObjects;
    if ("obj_attrs" in parent && parent.obj_attrs && Array.isArray(parent.obj_attrs)) {
        parentObjects = parent.obj_attrs;
    } else if ("objects" in parent && parent.objects && Array.isArray(parent.objects)) {
        parentObjects = parent.objects;
    } else {
        return;
    }

    for (const child of parentObjects) {
        if('figure_type' in child) {
            if (child.figure_type === FigureType.HEROLD) {
                drawHerold(child, svg);
                if (child.obj_attrs && child.obj_attrs.length) {
                    await dfsDrawToSvg(child, svg);
                }
            } else if (child.figure_type === FigureType.GENERAL) {
                const fieldParent = parent as FieldSpecific | ObjectFigureHeroldSpecific;
                drawGeneral(child, fieldParent, svg);
            }
        }
    }
}

/**
 * Draw SVG elements for a Herold figure.
 * @param herold The Herold figure object.
 * @param svg The SVG container to draw the figure in.
 */
function drawHerold(herold: ObjectFigureHeroldSpecific, svg: any) {
    svg.html(herold.svg)
        .attr("fill", herold.colourCode);
}

/**
 * Draw SVG elements for a General figure.
 * @param general The General figure object.
 * @param parent The parent object of the figure.
 * @param svg The SVG container to draw the figure in.
 */
function drawGeneral(general: ObjectFigureGeneralSpecific, parent: ObjectFigureHeroldSpecific | FieldSpecific, svg: any) {
    if (general.figureData === undefined || general.figureIndex === undefined || general.figureData[general.figureIndex] === undefined) {
        return;
    }

    const curFigure = general.figureData[general.figureIndex];

    if(!curFigure.svg) {
        return;
    }

    // Append the preprocessed SVG content to the SVG element
    const figureDom = svg.append("g").html(curFigure.svg);

    if(!figureDom) {
        return;
    }

    const firstSvgElement = figureDom.node().querySelector("svg");

    if(!firstSvgElement) {
        return;
    }

    const originalWidth = firstSvgElement.getAttribute("width");
    const originalHeight = firstSvgElement.getAttribute("height");

    const scaleX = parent.element_max_height / originalHeight;
    const scaleY = parent.element_max_width / originalWidth;
    const scale = Math.min(scaleX, scaleY);

    const newWidth = originalWidth * scale;
    const newHeight = originalHeight * scale;

    const translateX = (parent.element_x + (parent.element_max_width - newWidth) / 2) / scale;
    const translateY = (parent.element_y + (parent.element_max_height - newHeight) / 2) / scale;

    figureDom.attr("transform", `scale(${scale}) translate(${translateX}, ${translateY})`);

    if (general.colourCode && curFigure.color_css_name) {
        figureDom.selectAll("." + curFigure.color_css_name).style("fill", general.colourCode);
    }

    for (const possible_obj_attr of curFigure.possible_objects) {
        for (const obj_attr of general.obj_attrs) {
            if (possible_obj_attr.css_name && arraysHaveSameContent(obj_attr.name, possible_obj_attr.name)) {
                const elements = figureDom.selectAll("." + possible_obj_attr.css_name);
                elements.style("fill", obj_attr.colourCode); // Setting fill color
            }
        }
    }

    // Preprocess SVG content within figureDom
    const preprocessedSvg = preprocessSvg(figureDom.html());
    figureDom.html(preprocessedSvg);

}

function drawSplitLine(curItem: SplitSpecific, svg: any): void {
    switch (curItem.type) {
        case "UNSPLIT":
            break;
        case "VERTICAL":
            svg.append("line")
                .attr("x1", curItem.start_x + curItem.width / 2)
                .attr("y1", curItem.start_y)
                .attr("x2", curItem.start_x + curItem.width / 2)
                .attr("y2", curItem.start_y + curItem.height)
                .attr("stroke", "#000000")
                .attr("stroke-width", 2)
            break;
        case "HORIZONTAL":
            svg.append("line")
                .attr("x1", curItem.start_x)
                .attr("y1", curItem.start_y + curItem.height / 2)
                .attr("x2", curItem.start_x + curItem.width)
                .attr("y2", curItem.start_y + curItem.height / 2)
                .attr("stroke", "#000000")
                .attr("stroke-width", 2)
            break;
        default:
            break;
    }

    curItem.splits?.forEach( (children: any) => {
        drawSplitLine(children, svg);
    });

}


/**
 * Generate SVG representation of a blazon from specific JSON data.
 * @param blazonSpecificJsonData The specific JSON data representing the blazon.
 * @returns SVG string representing the blazon.
 */
function generateSvgBlazon(blazonSpecificJsonData : BlazonSpecificJson) : string {
    const shield = blazonSpecificJsonData.shield;
    const frame = shield.frame;

    const splitsJson = split.extractAndSortByFieldIx(shield.splits);

    // Create SVG container
    const svg = d3.create("svg")
        .attr("width", frame.width)
        .attr("height", frame.height)
        .attr("viewBox", `${-PADDING} ${-PADDING} ${frame.width + 2*PADDING} ${frame.height + 2*PADDING}`)
        .attr("xmlns", "http://www.w3.org/2000/svg")
    const defsNode = svg.append("defs");

    const maskShieldFrame = defsNode.append("mask")
        .attr("id", "shield_frame");

    // Mask of shield as path
    maskShieldFrame.append("path")
        .attr("d",frame.shieldPath)
        .attr("fill", "#FFFFFF");


    const splits = svg.append("g").attr("mask","url(#shield_frame)");

    let i = 0;
    splitsJson.forEach((field: any) => {
        const mask_id = "field_" + i;

        const maskShieldFrame1 = defsNode.append("mask")
            .attr("id", mask_id);

        // Mask of shield as path
        maskShieldFrame1.append("rect")
            .attr("x", field.start_x)
            .attr("y", field.start_y)
            .attr("width", field.width)
            .attr("height", field.height)
            .attr("fill", "#FFFFFF");

        const colourCode = shield.fields[i].colourCode;

        const fieldRect = splits.append("rect")
            .attr("x", field.start_x)
            .attr("y", field.start_y)
            .attr("width", field.width)
            .attr("height", field.height)

        if (colourCode !== undefined) {
            fieldRect.attr("fill", colourCode);
        }

        i++;
    });

    const shieldFiguresSection =  svg.append("g").attr("mask","url(#shield_frame)");


    shield.fields.forEach((field: any, index: number) => {
        const mask_id = "field_" + index;

        const fieldFiguresSection = shieldFiguresSection.append("g")
            .attr("mask","url(#" + mask_id + ")");

        // Iterate through objects
        dfsDrawToSvg(field, fieldFiguresSection);
    });

    const splitLines =  svg.append("g");

    drawSplitLine(shield.splits, splitLines);

    const shieldFrameBorder =  svg.append("g");

    shieldFrameBorder.append("path")
        .attr("id", "shield_border")
        .attr("d",frame.shieldPath)
        .attr("stroke", "#000000")
        .attr("stroke-width", 2)
        .attr("fill", "none");

    // Convert SVG container to HTML string
    const svgHtmlString = svg.node()?.outerHTML || '';

    return svgHtmlString;
}

export { generateSvgBlazon };
