import {findAttributeByTags, removeEntriesInListB} from "./utils";


import {HeroldFigureDelitel} from "./Delitel";
import {HeroldFigureData} from "../../api/IBlazonApiHeroldFigures";
import {HLAVA_DATA_HEIGHT, HLAVA_DATA_ROTATION, HlavaHeight, HlavaRotation} from "./HlavaData";

class HeroldFigureHlava extends HeroldFigureDelitel {

    static ATTR_DATASETS = [HLAVA_DATA_HEIGHT, HLAVA_DATA_ROTATION];

    private attrHeight: HlavaHeight;
    private attrRotation: HlavaRotation;

    constructor(start_x: number, start_y: number, width: number, height: number,
                tags_attributes: string[], quantity: number) {
        super(start_x, start_y, width, height, tags_attributes, quantity);

        let freeAttributes: string[] = [ ...this.tagsAttributes ];

        this.attrHeight = HlavaHeight.NORMAL;
        this.attrRotation = HlavaRotation.HORIZONTAL;

        for (let i = 0; i < HeroldFigureHlava.ATTR_DATASETS.length; i++) {
            const dataset = HeroldFigureHlava.ATTR_DATASETS[i];
            const attr = findAttributeByTags<any>(freeAttributes, dataset);

            if (attr !== null) {
                freeAttributes = removeEntriesInListB(freeAttributes, attr.names);

                switch (i) {
                    case 0:
                        this.attrHeight = attr.tag;
                        break;
                    case 1:
                        this.attrRotation = attr.tag;
                        break;
                    default:
                        throw new Error("Unexpected index in HeroldFigurePata.ATTR_DATASETS");
                }
            }
        }
    }

    getFigure(): HeroldFigureData {
        let width = this.width;
        let height = this.height;

        switch (this.attrHeight) {
            case HlavaHeight.NORMAL:
                height = Math.floor(this.height / 4);
                break;
            case HlavaHeight.SHORTER:
                height = Math.floor(this.height / 8);
                break;
            case HlavaHeight.LONGER:
                height = Math.floor(this.height / 3);
                break;
            default:
                throw new Error("Unexpected enum in SpecialFigureBrevno");
        }

        let start_center_x = this.start_x;
        let start_center_y = this.start_y + height/2;

        let end_center_x = width;
        let end_center_y = start_center_y;

        return this.getSimpleRectangle(start_center_x, start_center_y,
            end_center_x, end_center_y,
            height);
    }

}

export {HeroldFigureHlava};

