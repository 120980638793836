import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import {TreeItem2} from "@mui/x-tree-view";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    title: {
        paddingRight: '15px',
    },
}));

interface HandleChangeTreeItemObject {
    handleColorChange: any;
}

function TreeItemHeroldFigure({ itemId, itemColor, itemTitle, objIndex, handleChange, children }:
                                  {itemId: any, itemColor: string, itemTitle: string, objIndex: number[], handleChange: HandleChangeTreeItemObject, children?: React.ReactNode }) {
    const style = useStyles();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const renderTitle = (): React.ReactNode => {
        return <span className={style.title}>{itemTitle}</span>;
    }

    const renderColor = (): React.ReactNode => {

        const stylesInline = ({
            color: {
                background: `${itemColor}`,
            },
        });

        return (
            <div>
                <span className={style.title}>Color</span>

                <div className={style.swatch} onClick={handleClick}>
                    <div className={style.color} style={stylesInline.color}/>
                </div>

                {displayColorPicker && (
                    <div className={style.popover}>
                        <div className={style.cover} onClick={handleClose}></div>
                        <SketchPicker
                            color={itemColor}
                            onChange={(color) => handleChange.handleColorChange(color, objIndex)}
                        />
                    </div>
                )}
            </div>

        )
    }

    return (
        <TreeItem2
            key={itemId}
            itemId={itemId}
            label={renderTitle()}
        >
            <TreeItem2 itemId={itemId + "-color"} label={renderColor()}/>
            {children &&
                <TreeItem2 itemId={itemId + "-children"} label={"Childrens"}>
                    {children}
                </TreeItem2>
            }
        </TreeItem2>
    );
}


export {type HandleChangeTreeItemObject};

export default TreeItemHeroldFigure;
