import React from "react";
import {List, ListItem, Typography} from "@mui/material";
import {CblazonTestString} from "../../pages_types";
import TryCode from "../../../components/blazon-runner/TryCode";

const exampleBasic: CblazonTestString = {
    text: "V červeném štítě bílý kůl",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "kůl"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "bílý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleUzky: CblazonTestString = {
    text: "V červeném štítě bílý úzký kůl",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "kůl"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "bílý",
                                "úzký"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleQuantity: CblazonTestString = {
    text: "V červeném štítě tři bílé kůly",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "kůl"
                            ],
                            "quantity": 3,
                            "tag_attrs": [
                                "bílý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleShorten: CblazonTestString = {
    text: "V červeném štítě dole zkrácený bílý kůl",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "kůl"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zkrácený",
                                "dole",
                                "bílý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const examplePosition: CblazonTestString = {
    text: "",
    jsonBlazon:  {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "kůl"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "bílý",
                                "vlevo"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

function Kul() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Kůl</Typography>
            <Typography variant="body1">
                Kůl je jedním ze základních heroldických figur. Šířka kůlu není stanovena, obvykle šířka je, ale uvedena na jednu třetinu nebo dvě sedminy štítu.
            </Typography>

            <TryCode blazonText={exampleBasic.text} jsonBlazon={exampleBasic.jsonBlazon}></TryCode>

            <Typography variant="h2">Šířka kůlu</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je kůl v normální šířce
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>KUL_WIDTH_SMALL = tag_attrs: [ "úzký" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_WIDTH_LARGE = tag_attrs: [ "široký" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_WIDTH_NORMAL = nezadáno</ListItem>
            </List>
            <TryCode blazonText={exampleUzky.text} jsonBlazon={exampleUzky.jsonBlazon}></TryCode>

            <Typography variant="h2">Více kůlů</Typography>
            <Typography variant="body1">Element "quantity" vyjadřuje počet kůlů v poli.</Typography>
            <TryCode blazonText={exampleQuantity.text} jsonBlazon={exampleQuantity.jsonBlazon}></TryCode>

            <Typography variant="h2">Pozice kůlů</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je kůl ve středu pole.
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>KUL_POSITION_LEFT = tag_attrs: [ "vlevo" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_POSITION_RIGHT = tag_attrs: [ "vpravo" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_POSITION_CENTER = nezadáno</ListItem>
            </List>

            <TryCode blazonText={examplePosition.text} jsonBlazon={examplePosition.jsonBlazon}></TryCode>

            <Typography variant="h2">Zkrácený kůl</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je kůl v celé délce
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>KUL_SHORTEN_TOP = tag_attrs: [ "zkrácený", "nahoře" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_SHORTEN_BOTTOM = tag_attrs: [ "zkrácený", "dole" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_SHORTEN_BOTH = tag_attrs: [ "zkrácený", "oboustranně" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_SHORTEN_FULL = nezadáno</ListItem>
            </List>

            <TryCode blazonText={exampleShorten.text} jsonBlazon={exampleShorten.jsonBlazon}></TryCode>
        </div>
    );
}

export default Kul;
