import { createTheme } from '@mui/material/styles';
import type {} from "@mui/x-tree-view/themeAugmentation";

/**
 * Historical palette styled theme
 */
const themeHeraldic = createTheme({
    palette: {
        primary: {
            main: '#806358',
        },
        secondary: {
            main: '#deb596',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                // Style for h1 Typography
                h1: {
                    fontSize: '2rem',
                    fontWeight: 600,
                    color: '#333',
                    paddingBottom: "10px"
                },
                h2: {
                    fontSize: '1.8rem',
                    fontWeight: 400,
                    color: '#333',
                    paddingBottom: "10px"
                },
                body1: {
                    paddingBottom: "20px"
                }
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#806358'
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#806358',
                    '&:hover': {
                        backgroundColor: '#604a3f',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                    padding: 0,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                    },
                },
            },
        },
        MuiTreeItem2: {
            styleOverrides: {
                label: {
                    // Vertical alignment to center
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        }
    },
});

export default themeHeraldic;
