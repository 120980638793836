

/**
 * Class to calculate split sizes and positions for a given root item.
 */
class Split {


    /**
     * Calculates the sizes and positions of splits for the root item.
     * @param rootItem The root item representing the entire area to be split.
     * @param rootWidth The width of the root item.
     * @param rootHeight The height of the root item.
     */
    public calculateSplitsSizes(rootItem: any, rootWidth: number, rootHeight: number) {
        rootItem['start_x'] = 0;
        rootItem['start_y'] = 0;
        rootItem['width'] = rootWidth;
        rootItem['height'] = rootHeight;
        this.processSplit(rootItem);
    }


    /**
     * Extracts objects from nested structures and sorts them by the 'field_ix' property if present.
     * @param obj The object or array to extract and sort.
     * @returns An array of objects sorted by the 'field_ix' property.
     */
    public extractAndSortByFieldIx(obj: any): any {
        let results: any[] = [];
        if (Array.isArray(obj)) {
            obj.forEach(item => {
                results = results.concat(this.extractAndSortByFieldIx(item));
            });
        } else if (typeof obj === 'object' && obj !== null) {
            if ('field_ix' in obj) {
                results.push(obj);
            }
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    results = results.concat(this.extractAndSortByFieldIx(obj[key]));
                }
            }
        }
        return results.sort((a, b) => {
            if (a.field_ix && b.field_ix) {
                return parseInt(a.field_ix) - parseInt(b.field_ix);
            } else {
                return 0;
            }
        });
    }

    /**
     * Recursively processes each split item and calculates its size and position.
     * @param curItem The current item being processed.
     */
    private processSplit(curItem: any): void {
        switch (curItem.type) {
            case "UNSPLIT":
                break;
            case "VERTICAL":
                this.vertical(curItem);
                break;
            case "HORIZONTAL":
                this.horizontal(curItem);
                break;
            default:
                break;
        }

        curItem.splits.forEach( (children: any) => {
            this.processSplit(children);
        });
    }


    /**
     * Calculates sizes and positions for a horizontal split.
     * @param curItem The current item representing the horizontal split.
     */
    private horizontal(curItem: any): void {
        // Calculate the y-coordinate for the split
        const splitY = curItem.height / 2;

        var upper = curItem.splits[0];
        var lower = curItem.splits[1];
        upper['start_x'] = curItem.start_x;
        upper['start_y'] = curItem.start_y;
        upper['width'] = curItem.width;
        upper['height'] = splitY;

        lower['start_x'] = curItem.start_x;
        lower['start_y'] = curItem.start_y + splitY;
        lower['width'] = curItem.width;
        lower['height'] = splitY;
    }

    /**
     * Calculates sizes and positions for a vertical split.
     * @param curItem The current item representing the vertical split.
     */
    private vertical(curItem: any): void {
        // Calculate the y-coordinate for the split
        const splitX = curItem.width / 2;

        var left = curItem.splits[0];
        var right = curItem.splits[1];
        left['start_x'] = curItem.start_x;
        left['start_y'] = curItem.start_y;
        left['width'] = splitX;
        left['height'] = curItem.height;

        right['start_x'] = curItem.start_x + splitX;
        right['start_y'] = curItem.start_y;
        right['width'] = splitX;
        right['height'] = curItem.height;
    }
}

// Create an instance of the Split class
const split = new Split();


export default split;
