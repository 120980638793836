import {
    GeneralFigure,
    GeneralFigureEntry,
    GeneralFigureObject,
    IBlazonApiGeneralFigures
} from "../api/IBlazonApiGeneralFigures";
import {arraysHaveSameContent, calcDistanceArraysHaveSameContent, deepClone} from "../utils/ObjectUtils";
import {generalFiguresData} from "../mock-data/general-figures/figures";
import {ObjectSpecific} from "../types/types_blazonSpecific";
import {byTextAscending} from "../utils/SortInterface";

/**
 * Represents a mock implementation of the interface for managing general figures in a blazon.
 */
class BlazonApiGeneralFiguresMock implements IBlazonApiGeneralFigures {

    /**
     * The difference value assigned when a missing object is encountered during distance calculation.
     */
    static OBJ_DISTANCE_DIFFERENCE_MISSING_OBJECT = 100;

    /**
     * The difference value assigned when a tag attribute mismatch is encountered during distance calculation.
     */
    static OBJ_DISTANCE_DIFFERENCE_TAG = 1000;


    /**
     * Calculates the distance between two sets of general figure objects based on their attributes.
     * @param databaseObjects The objects stored in the database.
     * @param currentObjects The objects to compare against the database objects.
     * @param tree_height The height of the object tree, used for distance calculation.
     * @returns The distance between the two sets of objects.
     */
    getTreeObjectDistance(databaseObjects: GeneralFigureObject[], currentObjects: ObjectSpecific[], tree_height: number): number {
        let distance = 0;

        // Sort arrays by names
        for (const dbObject of databaseObjects) {
            dbObject.name.sort(byTextAscending(name => name));
        }

        for (const curObject of currentObjects) {
            curObject.name.sort(byTextAscending(name => name));
        }

        // sort them so i can compare them
        databaseObjects.sort(byTextAscending(obj => obj.name.join('')));
        currentObjects.sort(byTextAscending(obj => obj.name.join('')));

        const multiplierMissingObject = Math.ceil(BlazonApiGeneralFiguresMock.OBJ_DISTANCE_DIFFERENCE_MISSING_OBJECT / tree_height);
        const multiplierMissingAttribute = Math.ceil(BlazonApiGeneralFiguresMock.OBJ_DISTANCE_DIFFERENCE_TAG / tree_height);

        const differentSubObjects = Math.max(databaseObjects.length, currentObjects.length) - Math.min(databaseObjects.length, currentObjects.length)
        distance += differentSubObjects * multiplierMissingObject;

        for (let i = 0; i < Math.min(databaseObjects.length, currentObjects.length); i++) {
            const dbObject = databaseObjects[i];
            const curObject = currentObjects[i];

            if(!arraysHaveSameContent(dbObject.name, curObject.name)) {
                distance += multiplierMissingAttribute * calcDistanceArraysHaveSameContent(dbObject.tags, curObject.tag_attrs);

                if(dbObject.possible_objects !== undefined && curObject.obj_attrs !== undefined) {
                    distance += this.getTreeObjectDistance(dbObject.possible_objects, curObject.obj_attrs, tree_height + 1);
                }
            }
        }
        return distance;
    }

    async getMatchFigures(czechName: string[], czechTagAttributes: string[],
                    czechObjAttributes: ObjectSpecific[], naturalColors: boolean): Promise<GeneralFigureEntry[]> {

        let closestMatches: GeneralFigureEntry[] = [];

        const maxReturnedEntries = 3;

        // Iterate through specialFiguresData array
        for (const figure of generalFiguresData) {
            // Check if the current item's name matches the given name
            const isMatch = arraysHaveSameContent(figure.name, czechName);
            let distance = 0;

            if (isMatch) {
                distance += BlazonApiGeneralFiguresMock.OBJ_DISTANCE_DIFFERENCE_TAG * calcDistanceArraysHaveSameContent(figure.tags, czechTagAttributes);

                distance += this.getTreeObjectDistance(figure.possible_objects, czechObjAttributes, 1);

                // if we have less then "maxReturnedEntries" add match to list
                if (closestMatches.length < maxReturnedEntries) {
                    let output = deepClone(figure);
                    output.distance = distance;

                    closestMatches.push(output);
                } else {
                    // otherwise find if this value distance is better than already in list
                    const maxDistanceIndex = closestMatches.findIndex(match => match.distance === Math.max(...closestMatches.map(match => match.distance)));

                    // If the current distance is less than the largest distance in the list, replace it
                    if (distance < closestMatches[maxDistanceIndex].distance) {
                        let output = deepClone(figure);
                        output.distance = distance;
                        closestMatches[maxDistanceIndex] = output;
                    }
                }
            }
        }

        closestMatches.sort((a, b) => a.distance - b.distance);

        // If match found, fetch the SVG file by filename
        try {
            for (const item of closestMatches) {
                const response = await fetch(`/svg_figures/${item.filename}`);
                item.svg = await response.text();
            }

            return Promise.resolve(closestMatches);
        } catch (error) {
            console.error('Error fetching SVG file:', error);
            return Promise.reject(error);
        }

    }

    async getAllFigures(): Promise<GeneralFigureEntry[]> {

        let figures: GeneralFigureEntry[] = deepClone(generalFiguresData);

        figures.sort(byTextAscending(obj => obj.name.join('')));

        // If match found, fetch the SVG file by filename
        try {
            for (const item of figures) {
                const response = await fetch(`/svg_figures/${item.filename}`);
                item.svg = await response.text();
            }

            return Promise.resolve(figures);
        } catch (error) {
            console.error('Error fetching SVG file:', error);
            return Promise.reject(error);
        }

    }

}

export {BlazonApiGeneralFiguresMock};
