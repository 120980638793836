import * as d3 from 'd3';

/**
 * Cleans up a string representing SVG content by removing extra whitespaces and newline characters.
 * @param textSvg The string containing SVG content.
 * @returns The cleaned SVG string.
 */
function cleanStringSvg(textSvg: string) {
    return textSvg.replace(/\s+/g, ' ')
                  .replace(/\n/g, '');
}


let idCounter = 0; // Counter variable for generating unique IDs


/**
 * Generates a unique ID string for DOM elements.
 *
 * @returns A unique ID string.
 */
function generateUniqueId(): string {
    return `generatedId_${idCounter++}`; // Increment the counter for each call
}

/**
 * Parses a string of CSS inline styles into a JavaScript object.
 *
 * @param styleString - The string containing CSS inline styles.
 * @returns An object representing the parsed inline styles.
 */
function parseInlineStyles(styleString: string) {
    const styles: any = {};
    if (!styleString) return styles;

    styleString.split(';').forEach(style => {
        const [property, value] = style.split(':').map(part => part.trim());
        if (property && value) {
            styles[property] = value;
        }
    });

    return styles;
}

/**
 * Preprocesses the SVG content by updating IDs, removing class names, and merging inline styles.
 *
 * @param svgContent - The SVG content to preprocess.
 * @returns The preprocessed SVG content.
 */
function preprocessSvg(svgContent: string): string {
    // Parse SVG content into DOM
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');

    // Track existing IDs
    const existingIds: Map<string, string> = new Map();
    const classStyleMap = parseSvgStyles(svgContent);

    // Function to update IDs referenced in attributes
    function updateAttributeIds(element: Element, attributeName: string, currentId: string, newId: string) {
        const attributeValue = element.getAttribute(attributeName);
        if (attributeValue && attributeValue.includes(`#${currentId}`)) {
            const updatedValue = attributeValue.replace(`#${currentId}`, `#${newId}`);
            element.setAttribute(attributeName, updatedValue);
        }
    }


    // Iterate over all elements and preprocess
    doc.querySelectorAll('*').forEach(element => {
        // Assign unique IDs
        const currentId = element.getAttribute('id');
        if (currentId) {
            let newId = existingIds.get(currentId);
            if(newId == undefined) {
                do {
                    newId = generateUniqueId();
                } while (existingIds.has(newId)); // Ensure the new ID is unique
                existingIds.set(currentId, newId);
            }

            element.setAttribute('id', newId);
        }

        // Remove class names and collect styles
        const classNames = element.getAttribute('class');
        if (classNames) {
            const classList = classNames.split(' ');
            classList.forEach(className => {
                const inlineStyle = classStyleMap[`.${className}`];
                if (inlineStyle) {
                    // Merge existing inline styles with class-based styles
                    const existingInlineStyle = element.getAttribute('style') || '';
                    const existingStyles = parseInlineStyles(existingInlineStyle);
                    const newStyles = parseInlineStyles(inlineStyle);

                    // Merge new styles into existing styles, giving priority to existing styles
                    for (const property in newStyles) {
                        // Check if the property already exists in existing styles
                        if (!existingStyles.hasOwnProperty(property)) {
                            existingStyles[property] = newStyles[property]; // Add new property
                        }
                    }

                    // Convert merged styles back to a string
                    const mergedStyleString = Object.entries(existingStyles)
                        .map(([property, value]) => `${property}: ${value}`)
                        .join('; ');

                    // Set the merged styles as the new inline style
                    element.setAttribute('style', mergedStyleString);
                }
            });
            element.removeAttribute('class'); // Remove class attribute
        }
    });

    doc.querySelectorAll('*').forEach(element => {
        const attributes = element.attributes;
        for (let i = 0; i < attributes.length; i++) {
            const attributeName = attributes[i].name;
            const attributeValue = attributes[i].value;

            if(attributeName == 'style') {
                break;
            } else if (attributeName.includes("color")) {
                break;
            }

            const match = attributeValue.match(/#[\w]+(?=[);]|)/g);
            if(match) {

                for(let i = 0; i < match.length; i++) {
                    const oldId = match[0].substring(1);

                    const newId = existingIds.get(oldId);

                    if(newId !== undefined) {
                        const updatedAttributeValue = attributeValue.replace(`#${oldId}`, `#${newId}`);
                        element.setAttribute(attributeName, updatedAttributeValue);
                    }
                }
            }
        }
    })

    // Remove <style> elements
    doc.querySelectorAll('style').forEach(style => {
        style.parentNode?.removeChild(style);
    });

    return doc.documentElement.outerHTML;
}

/**
 * Scales raw SVG content to fit within specified dimensions and applies translation.
 * @param svg The raw SVG content as a string.
 * @param elementMaxWidth The maximum width of the container element.
 * @param elementMaxHeight The maximum height of the container element.
 * @param translateX The horizontal translation to apply.
 * @param translateY The vertical translation to apply.
 * @returns The scaled and translated SVG content as a string.
 */
function scaleRawSvg(svg: string, elementMaxWidth: number, elementMaxHeight: number, translateX: number, translateY: number): string {
    // Create a new SVG element
    const svgElement = d3.create("svg")
        .attr("width", elementMaxWidth)
        .attr("height", elementMaxHeight)
        .attr("viewBox", `0 0 ${elementMaxWidth} ${elementMaxHeight}`);


    // Preprocess SVG content
    const preprocessedSvg = preprocessSvg(svg);

    // Append the preprocessed SVG content to the SVG element
    const figureDom = svgElement.append("g").html(preprocessedSvg);

    // Get the first SVG element within the created SVG
    const firstSvgElement = figureDom.select("svg");

    if (!firstSvgElement) {
        return "";
    }

    // Get the original width and height of the figure
    const originalWidth = parseFloat(firstSvgElement.attr("width") || "1");
    const originalHeight = parseFloat(firstSvgElement.attr("height") || "1");

    // Calculate scale
    const scaleX = elementMaxHeight / originalHeight;
    const scaleY = elementMaxWidth / originalWidth;
    const scale = Math.min(scaleX, scaleY);

    // Calculate new width and height
    const newWidth = originalWidth * scale;
    const newHeight = originalHeight * scale;

    // Calculate translation
    const translateCurX = (translateX + (elementMaxWidth - newWidth) / 2) / scale;
    const translateCurY = (translateY + (elementMaxHeight - newHeight) / 2) / scale;

    // Apply scale and translation
    figureDom.attr("transform", `scale(${scale}) translate(${translateCurX}, ${translateCurY})`);

    // Return the modified SVG element as a string
    return svgElement.node()?.outerHTML || "";
}

/**
 * Parses CSS styles from SVG content and maps them to class names.
 *
 * @param svgContent - The SVG content containing CSS styles.
 * @returns An object mapping class names to inline CSS styles.
 */
const parseSvgStyles = (svgContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');
    const styles = doc.querySelectorAll('style');
    const classStyleMap: { [key: string]: string } = {};

    styles.forEach(style => {
        const cssText = style.textContent || '';
        const rules = cssText.split('}');
        rules.forEach(rule => {
            const [selectors, styleText] = rule.split('{');
            if (selectors && styleText) {
                const classNames = selectors.split(',').map(selector => selector.trim());
                const inlineStyle = styleText.trim();
                classNames.forEach(className => {
                    classStyleMap[className] = inlineStyle;
                });
            }
        });
    });

    return classStyleMap;
};


export {cleanStringSvg, scaleRawSvg, generateUniqueId, parseSvgStyles, preprocessSvg};
