import {HeroldFigure} from "../types_HeroldFigure";

abstract class HeroldFigureTriangle extends HeroldFigure {

    /*
    getTriangleSimple(point1_x: number, point1_y: number,
                      point2_x: number, point2_y: number,
                      point3_x: number, point3_y: number) {
        // M 0 0 L 150 300 L 300 0 Z

        const PADDING_VERTICAL = 30;
        const PADDING_HORIZONTAL = 10;

        // Construct the SVG path using template literals
        const path = cleanStringSvg(`
            M ${point1_x} ${point1_y}
            L ${point2_x} ${point2_y}
            L ${point3_x} ${point3_y}
            Z
        `);

        const svg = `<g><path d="${path}" /></g>`;

        return {width: this.width, height: this.height, svg: svg,
            element_x: this.start_x, element_y: max_bounding_rect[1],
            element_max_width: max_bounding_rect[2] - max_bounding_rect[0],element_max_height: max_bounding_rect[3] - max_bounding_rect[1]};
    }

    */
}

export {HeroldFigureTriangle};
