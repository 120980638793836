import {findAttributeByTags, removeEntriesInListB} from "./utils";

import {
    BREVNO_DATA_POSITIONS,
    BREVNO_DATA_ROTATION,
    BREVNO_DATA_SHORTEN,
    BREVNO_DATA_STYLES,
    BREVNO_DATA_TIPS,
    BREVNO_DATA_WIDTH,
    BrevnoPosition,
    BrevnoRotation,
    BrevnoShorten,
    BrevnoStyle,
    BrevnoTip,
    BrevnoWidth
} from "./BrevnoData";
import {HeroldFigureDelitel} from "./Delitel";
import {HeroldFigureData} from "../../api/IBlazonApiHeroldFigures";

class HeroldFigureBrevno extends HeroldFigureDelitel {

    static ATTR_DATASETS = [BREVNO_DATA_STYLES, BREVNO_DATA_ROTATION, BREVNO_DATA_SHORTEN,
        BREVNO_DATA_TIPS, BREVNO_DATA_POSITIONS, BREVNO_DATA_WIDTH];

    private attrStyle: BrevnoStyle;
    private attrShorten: BrevnoShorten;
    private attrTip: BrevnoTip;
    private attrPosition: BrevnoPosition;
    private attrWidth: BrevnoWidth;
    private attrRotation: BrevnoRotation;

    constructor(start_x: number, start_y: number, width: number, height: number,
                tags_attributes: string[], quantity: number) {
        super(start_x, start_y, width, height, tags_attributes, quantity);

        let freeAttributes: string[] = [ ...this.tagsAttributes ]

        this.attrStyle = BrevnoStyle.NONE;
        this.attrShorten = BrevnoShorten.FULL;
        this.attrTip = BrevnoTip.NONE;
        this.attrPosition = BrevnoPosition.CENTER;
        this.attrWidth = BrevnoWidth.NORMAL;
        this.attrRotation = BrevnoRotation.HORIZONTAL;

        for (let i = 0; i < HeroldFigureBrevno.ATTR_DATASETS.length; i++) {
            const dataset = HeroldFigureBrevno.ATTR_DATASETS[i];
            const attr = findAttributeByTags<any>(freeAttributes, dataset);

            if (attr !== null) {
                freeAttributes = removeEntriesInListB(freeAttributes, attr.names);

                switch (i) {
                    case 0:
                        this.attrStyle = attr.tag;
                        break;
                    case 1:
                        this.attrRotation = attr.tag;
                        break;
                    case 2:
                        this.attrShorten = attr.tag;
                        break;
                    case 3:
                        this.attrTip = attr.tag;
                        break;
                    case 4:
                        this.attrPosition = attr.tag;
                        break;
                    case 5:
                        this.attrWidth = attr.tag;
                        break;
                    default:
                        throw new Error("Unexpected index in SpecialFigureKul.ATTR_DATASETS");
                }
            }
        }
    }

    getFigure(): HeroldFigureData {
        let widthRect = 200;
        let width = this.width;
        let height = this.height;

        let start_center_x = this.start_x;
        let start_center_y = this.start_y + (this.height / 2);

        let end_center_x = start_center_x + width;
        let end_center_y = start_center_y;

        let coeficientWidth = 1;

        switch (this.attrRotation) {
            case BrevnoRotation.HORIZONTAL:
                start_center_y = this.start_y + (this.height / 2);
                end_center_y = start_center_y;

                start_center_x = this.start_x;
                end_center_x = this.start_x + this.width;
                break
            case BrevnoRotation.DIAGONAL_KOSME:
                start_center_y = this.start_y;
                end_center_y = this.start_y + this.height;

                start_center_x = this.start_x;
                end_center_x = this.start_x + this.width;
                break;
            case BrevnoRotation.DIAGONAL_SIKME:
                start_center_y = this.start_y;
                end_center_y = this.start_y + this.height;

                start_center_x = this.start_x + this.width;
                end_center_x = this.start_x;
        }

        switch (this.attrPosition) {
            case BrevnoPosition.CENTER:
                break;
            case BrevnoPosition.UPPER:
                start_center_y -= (this.height / 4);
                end_center_y -=  (this.height / 4);
                break;
            case BrevnoPosition.LOWER:
                start_center_y += (this.height / 4);
                end_center_y += (this.height / 4);
                break;
            default:
                throw new Error("Unexpected enum in SpecialFigureBrevno");
        }

        switch (this.attrWidth) {
            case BrevnoWidth.NORMAL:
                widthRect = Math.floor(this.height / 4);
                coeficientWidth = 1;
                break;
            case BrevnoWidth.LARGE:
                widthRect = Math.floor(this.height / 2);
                coeficientWidth = 1.3;
                break;
            case BrevnoWidth.SMALL:
                widthRect = Math.floor(this.height / 8);
                coeficientWidth = 0.7;
                break;
            default:
                throw new Error("Unexpected enum in SpecialFigureBrevno");
        }


        switch (this.attrShorten) {
            case BrevnoShorten.FULL:
                break;
            case BrevnoShorten.SHORTEN_LEFT:
                end_center_x -= this.width / 8;
                break;
            case BrevnoShorten.SHORTEN_RIGHT:
                start_center_x += this.width / 8;
                break;
            case BrevnoShorten.SHORTED_BOTH:
                start_center_x += this.width / 8;
                end_center_x -= this.width / 8;
                break;
            default:
                throw new Error("Unexpected index in SpecialFigureKul.getFigure");
        }

        if(this.quantity <= 1) {
            return this.getSimpleRectangle(start_center_x, start_center_y,
                end_center_x, end_center_y,
                widthRect);
        } else {
            // vector from 2 points from end_center to start center
            const vector_start_center_x = end_center_x - start_center_x;
            const vector_start_center_y = end_center_y - start_center_y;

            // height of rectangle determined by euclid distance
            const widthAllDelitels = Math.sqrt(Math.pow(vector_start_center_x, 2) + Math.pow(vector_start_center_y, 2));
            return this.getRectangleWithDelitels(start_center_x, start_center_y,
                end_center_x, end_center_y,
                Math.max(widthAllDelitels, this.height), this.quantity, coeficientWidth);
        }
    }

}

export {HeroldFigureBrevno};

