import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme: any) => ({
    header: {
        backgroundColor: '#806358',
    },
    navButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    drawerButton: {
        marginRight: 4,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    menuTextTitle: {
        color: '#000000'
    },
    drawer: {
        width: '100%',
    },
    drawerPaper: {
        position: 'sticky',
        width: '100%',
    },
    logo: {
        marginRight: theme.spacing(1),
        maxHeight: '40px', // maximum height for the logo
    },
    headerTitle: {
        flexGrow: 1
    },
}));

/**
 * Array of menu items for the navigation.
 */
const menuItems = [
    {
        menuTitle: "Domů",
        pageURL: "/",
    },
    {
        menuTitle: "Dokumentace",
        pageURL: "/documentation",
    },
    {
        menuTitle: "O aplikaci",
        pageURL: "/about",
    },
];

/**
 * HeaderNavbar component for displaying the header and navigation drawer.
 */
const HeaderNavbar = () => {
    const styles = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);


    /**
     * Toggles the state of the drawer.
     * @param {boolean} open - Whether the drawer should be open or closed.
     */
    const toggleDrawer = (open: any) => (event: any) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <React.Fragment>
            <AppBar position="sticky" className={styles.header}>
                <Toolbar>
                    <img src="/blazon_logo.png" alt="Logo" className={styles.logo}/>
                    <Typography variant="h6" component="div" className={styles.headerTitle}>
                        Český Blazon generator
                    </Typography>
                    <Box marginLeft="auto">
                        <Box className={styles.navButton}>
                            {menuItems.map(item => (
                                <Button
                                    key={item.pageURL}
                                    color="inherit"
                                    component={Link}
                                    to={item.pageURL}
                                >
                                    {item.menuTitle}
                                </Button>
                            ))}
                        </Box>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            className={styles.drawerButton}
                            sx={{display: {sm: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                className={styles.drawer}
            >
                <List>
                    {menuItems.map(item => (
                        <ListItem
                            key={item.pageURL}
                            component={Link}
                            to={item.pageURL}
                            onClick={toggleDrawer(false)}
                        >
                            <ListItemText primary={item.menuTitle} className={styles.menuTextTitle} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </React.Fragment>
    );
};

export default HeaderNavbar;
