import PageContentSidebar, {ItemType} from "../components/page-wrapper/PageContentSidebar";
import React from "react";
import Overview from "./documentation/Overview";
import Kul from "./documentation/figures-heraldic/Kul";
import Brevno from "./documentation/figures-heraldic/Brevno";
import Splitting from "./documentation/Splitting";
import SvgGeneralFigures from "./SvgGeneralFigures";
import GovermentCzech from "./documentation/examples/GovermentCzech";
import Towns1 from "./documentation/examples/Towns1";
import ColorsList from "./documentation/ColorsList";
import Hlava from "./documentation/figures-heraldic/Hlava";
import Pata from "./documentation/figures-heraldic/Pata";
import Klin from "./documentation/figures-heraldic/Klin";
import Spice from "./documentation/figures-heraldic/Spice";

/**
 * Documentation routes
 */
const documentationRoutes = [
    {
        title: "Přehled",
        type: ItemType.PAGE,
        path: '',
        element: <Overview />,
    },
    {
        title: "Příklady znaků",
        type: ItemType.LIST,
        items:[
            {
                type: ItemType.PAGE,
                title: "České státní znaky",
                path: 'statni_znaky',
                element: <GovermentCzech />,
            },
            {
                type: ItemType.PAGE,
                title: "Města a obce 1",
                path: 'mesta1',
                element: <Towns1 />,
            }
        ],
    },
    {
        title: "Heroldické figury",
        type: ItemType.LIST,
        items:[
            {
                type: ItemType.PAGE,
                title: "Kůl",
                path: 'kul',
                element: <Kul />,
            },
            {
                type: ItemType.PAGE,
                title: "Břevno",
                path: 'brevno',
                element: <Brevno />,
            },
            {
                type: ItemType.PAGE,
                title: "Hlava",
                path: 'hlava',
                element: <Hlava />,
            },
            {
                type: ItemType.PAGE,
                title: "Pata",
                path: 'pata',
                element: <Pata />,
            },
            {
                type: ItemType.PAGE,
                title: "Klín",
                path: 'klin',
                element: <Klin />,
            },
            {
                type: ItemType.PAGE,
                title: "Špice",
                path: 'spice',
                element: <Spice />,
            }
        ],
    },
    {
        title: "Obecné figury",
        type: ItemType.PAGE,
        path: 'obecne_figury',
        element: <SvgGeneralFigures/>
    },
    {
        title: "Dělení štítu",
        type: ItemType.PAGE,
        path: 'deleni',
        element: <Splitting />,
    },
    {
        title: "Tinktury",
        type: ItemType.PAGE,
        path: 'tinktury',
        element: <ColorsList />,
    },
];
const Documentation = () => {
    return (
        <PageContentSidebar basePath={"/documentation"} routes={documentationRoutes}>
            <div></div>
        </PageContentSidebar>
    );
};

export default Documentation;
