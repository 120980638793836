import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Theme } from "@mui/material/styles";
import { Container, Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

enum ItemType {
    PAGE,
    LIST
}

const WRAPPER_PADDING_TOP = "40px"
const WRAPPER_PADDING_BOTTOM = "40px"

const CONTENT_PADDING_TOP = "40px"
const CONTENT_PADDING_BOTTOM = "40px"

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        margin: 0,
        padding: 0,
        paddingTop: WRAPPER_PADDING_TOP,
        paddingBottom: WRAPPER_PADDING_BOTTOM,
        [theme.breakpoints.down('md')]: {
            background: 'none', // Remove background on mobile
        },
        backgroundColor: '#f0f0f0',
    },
    content_body: {
        maxWidth: '1200px', // max-width for content
        margin: '0 auto', // center content horizontally
        backgroundColor: '#ffffff',
        paddingTop: CONTENT_PADDING_TOP,
        paddingBottom: CONTENT_PADDING_BOTTOM,
        // styles of body for mobile devices
        [theme.breakpoints.down('md')]: {
            borderStyle: 'none',
            borderWidth: 'none',
            borderColor: 'none',
        },
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#b2b2b2",
    },
    root: {
        margin: "0 auto",
        display: 'inherit',
        maxWidth: "1400px",
    },
    columns: {
        display: 'flex',
    },
    sidebar: {
        width: "240px",
        flexShrink: 0,
        padding: 20
    },
    list: {
        marginLeft: 20,
        '& .MuiTypography-root': {
            padding: 0, // remove padding from ListItemText
        },
    },
    content: {
        width: '100%',
        maxWidth: "1000px",
    },
    selected: {
        backgroundColor: '#D2B48C!important',
    },
    treeItem: {
        '& .MuiTypography-root': {
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    iconHamburger: {
        border: "1px solid #000000", // add border to the IconButton
    }
}));

const PageContentSidebar = ({ routes, basePath, children }: { routes: any, basePath: any, children: any }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')); // Check if the screen is mobile

    const [openDrawer, setOpenDrawer] = useState(false);

    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const contentHeight = `calc(100vh)`;

    const handleListItemClick = (path: string) => {
        // Update the URL without redirecting
        navigate(path);
    };

    const renderItems = (items: any[]) => {
        return items.map((item: any, index: number) => {
            if (item.type === ItemType.PAGE) {
                const itemPath = item.path === '' ? basePath : basePath + "/" + item.path;
                const isSelected = location.pathname == itemPath; // Check if the item is selected
                let listItemClasses = '';
                if (isSelected) {
                    listItemClasses = classes.selected;
                }
                return (
                    <TreeItem2
                        key={index}
                        itemId={item.title}
                        label={<Typography fontWeight="regular">{item.title}</Typography>}
                        className={`${classes.treeItem} ${listItemClasses}`}
                        onClick={() => handleListItemClick(item.path)}
                    />
                );
            } else if (item.type === ItemType.LIST) {
                return (
                    <TreeItem2
                        key={index}
                        itemId={item.title}
                        className={classes.treeItem}
                        label={<Typography fontWeight="bold">{item.title}</Typography>}
                    >
                        {renderItems(item.items)}
                    </TreeItem2>
                );
            }
            return null;
        });
    };

    const renderRoutes: any = (routes: any[]) => {
        return routes.map((route: any, index: number) => {
            if (route.type === ItemType.PAGE) {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                    />
                );
            } else if (route.type === ItemType.LIST) {
                return renderRoutes(route.items);
            }
            return null;
        });
    };

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.content_body} style={{ minHeight: contentHeight }}>
                <div className={classes.root}>
                    {isMobile && (
                        <Container>
                            <IconButton sx={{backgroundColor: "#dadada", borderRadius: 2}} onClick={toggleDrawer}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
                                <Box className={classes.sidebar}>
                                    <SimpleTreeView

                                    >
                                        {renderItems(routes)}
                                    </SimpleTreeView>
                                </Box>
                            </Drawer>
                        </Container>
                    )}
                    <div className={classes.columns}>
                        {!isMobile && (
                            <Box className={classes.sidebar}>
                                <SimpleTreeView
                                >
                                    {renderItems(routes)}
                                </SimpleTreeView>
                            </Box>
                        )}

                        <Box className={classes.content}>
                            <Routes>
                                {renderRoutes(routes)}
                            </Routes>

                            {/* Render children here if needed */}
                            {children}
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageContentSidebar;
export { ItemType };
