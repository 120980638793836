import {HeroldFigureType} from "./HeroldFigureType";


interface HeroldFiguresDataProps {
    names: string[],
    figure_type: HeroldFigureType
}

export const heroldFiguresData: HeroldFiguresDataProps[] = [
    {
        names: ["klín"],
        figure_type: HeroldFigureType.KLIN
    },
    {
        names: ["kůl"],
        figure_type: HeroldFigureType.KUL
    },
    {
        names: ["břevno"],
        figure_type: HeroldFigureType.BREVNO
    },
    {
        names: ["pata"],
        figure_type: HeroldFigureType.PATA
    },
    {
        names: ["trávník"],
        figure_type: HeroldFigureType.PATA_TRAVNIK
    },
    {
        names: ["hlava"],
        figure_type: HeroldFigureType.HLAVA
    },
    {
        names: ["špice"],
        figure_type: HeroldFigureType.SPICE
    },
]
