import React from "react";
import {SimpleTreeView, TreeItem2} from "@mui/x-tree-view";
import {ColorResult} from "react-color";
import TreeItemField from "./TreeItemField";
import TreeItemHeroldFigure from "./TreeItemHeroldFigure";
import TreeItemGeneralFigure, {HandleChangeTreeGeneralItemObject} from "./TreeItemGeneralFigure";
import {FigureType} from "../../genblazon/types/types_blazonSpecific";

interface JsonSpecificEditorProps {
    jsonSpecificData: any;
    setSpecificData: React.Dispatch<React.SetStateAction<any>>;
}

function JsonSpecificEditor({ jsonSpecificData, setSpecificData }: JsonSpecificEditorProps) {

    const handleColorChange = (color: ColorResult, fieldIndex: number) => {
        const newData = { ...jsonSpecificData };
        newData.shield.fields[fieldIndex].colourCode = color.hex;
        setSpecificData(newData);
    };

    const handleObjectColorChange = (color: ColorResult, fieldIndex: number[]) => {
        if(fieldIndex.length >= 2) {

            let foundedObj = jsonSpecificData.shield.fields[fieldIndex[0]].objects[fieldIndex[1]];
            for (let i = 2; i < fieldIndex.length; i++) {
                foundedObj = foundedObj.obj_attrs[fieldIndex[i]];
            }


            if(foundedObj !== undefined) {
                for(let i = 2; i < fieldIndex.length; i++) {
                    if(foundedObj.obj_attrs[i] !== undefined) {
                        foundedObj = foundedObj.obj_attrs[i];
                    }
                }

                if(foundedObj !== undefined) {
                    const newData = { ...jsonSpecificData };
                    foundedObj.colourCode = color.hex;
                    setSpecificData(newData);
                }
            }
        }
    };

    const handleFigureChange = (figureIndex: number, fieldIndex: number[]) => {
        if(fieldIndex.length >= 2) {

            let foundedObj = jsonSpecificData.shield.fields[fieldIndex[0]].objects[fieldIndex[1]];
            for (let i = 2; i < fieldIndex.length; i++) {
                foundedObj = foundedObj.obj_attrs[fieldIndex[i]];
            }


            if(foundedObj !== undefined) {
                for(let i = 2; i < fieldIndex.length; i++) {
                    if(foundedObj.obj_attrs[i] !== undefined) {
                        foundedObj = foundedObj.obj_attrs[i];
                    }
                }

                if(foundedObj !== undefined) {
                    const newData = { ...jsonSpecificData };
                    foundedObj.figureIndex = figureIndex;
                    setSpecificData(newData);
                }
            }
        }
    };

    const processObjects = (objects: any, treeHeight: number[]): React.ReactNode[] => {
        const handler: HandleChangeTreeGeneralItemObject = {
            handleColorChange: handleObjectColorChange,
            handleFigureChange: handleFigureChange
        }

        return objects.map((figure: any, index: number) => {
            const treeHeightCopy = [...treeHeight, index];
            const itemId = `obj-${treeHeightCopy.join('-')}`;

            if(figure.figure_type == FigureType.HEROLD) {
                return (
                    <TreeItemHeroldFigure
                        key={itemId}
                        itemId={itemId}
                        itemColor={figure.colourCode}
                        itemTitle={figure.name.join('.')}
                        objIndex={treeHeightCopy}
                        handleChange={handler}
                    >
                        {/* Recursive call to process children */}
                        {figure.obj_attrs && figure.obj_attrs.length > 0 && (
                            processObjects(figure.obj_attrs, treeHeightCopy)
                        )}
                    </TreeItemHeroldFigure>
                );
            } else if(figure.figure_type == FigureType.GENERAL) {
                if (figure.figureData === undefined || figure.figureData.length === 0) {
                    return null; // Return null when no figure data
                }

                return (
                    <TreeItemGeneralFigure
                        key={itemId}
                        itemId={itemId}
                        objFigure={figure}
                        objIndex={treeHeightCopy}
                        handleChange={handler}
                    >
                    </TreeItemGeneralFigure>
                );
            } else {
                return null; // Return null for other cases
            }

        });
    };

    const defaultExpanded: string[] = [];

    // Expand default all fields
    if(jsonSpecificData.shield.fields !== undefined) {
        for(let i = 0; i < jsonSpecificData.shield.fields.length; i++) {
            defaultExpanded.push(`field-${i}`);
        }
    }


    return (
        <SimpleTreeView defaultExpandedItems={[ ...defaultExpanded, "shield", "fields"]}>
            { jsonSpecificData.shield &&
                <TreeItem2 itemId={"shield"} label={"Shield"}>
                    { jsonSpecificData.shield.fields &&
                        <TreeItem2 itemId={"fields"} label={"Fields"}>
                            {jsonSpecificData.shield.fields.map((field: { colourCode: any, objects: any }, fieldIndex: number) => (
                                <TreeItemField
                                    key={fieldIndex}
                                    itemId={`field-${fieldIndex}`}
                                    label={field.colourCode}
                                    handleColorChange={handleColorChange}
                                    fieldIndex={fieldIndex}
                                >
                                    {(field.objects && Array.isArray(field.objects) && field.objects.length !== 0) && (
                                        processObjects(field.objects, [ fieldIndex ])
                                    )}
                                </TreeItemField>
                            ))}
                        </TreeItem2>
                    }
                </TreeItem2>
            }
        </SimpleTreeView>
    );
}

export default JsonSpecificEditor;
