import {AttributeEntry} from "../types_HeroldFigure";

export enum PataHeight {
    NORMAL,
    SHORTER,
    LONGER,
}

export enum PataRotation {
    HORIZONTAL,
    DIAGONAL_KOSME,
    DIAGONAL_SIKME
}



export const PATA_DATA_HEIGHT: AttributeEntry<PataHeight>[] = [
    {
        names: ["zvýšený"],
        tag: PataHeight.LONGER
    },
    {
        names: ["vyvýšený"],
        tag: PataHeight.LONGER
    },
    {
        names: ["snížený"],
        tag: PataHeight.SHORTER
    },
    {
        names: ["poloviční"],
        tag: PataHeight.SHORTER
    },
    {
        names: [],
        tag: PataHeight.NORMAL
    },
]

export const PATA_DATA_ROTATION: AttributeEntry<PataRotation>[] = [
    {
        names: ["šikmý"],
        tag: PataRotation.DIAGONAL_SIKME
    },
    {
        names: ["kosmý"],
        tag: PataRotation.DIAGONAL_KOSME
    },
    {
        names: [],
        tag: PataRotation.HORIZONTAL
    },
]
