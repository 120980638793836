// Function to search for figure_type by name

import {heroldFiguresData} from "../../mock-data/herold-figures/HeroldFiguresData";
import {AttributeEntry} from "../types_HeroldFigure";

/**
 * Finds the figure_type associated with the given name(s) in the Herold Figures dataset.
 * @param name - The name(s) of the figure to search for.
 * @returns The figure_type corresponding to the given name(s), or null if no match is found.
 */
function findFigureTypeByName(name: string[]) {
    // Iterate through specialFiguresData array
    for (const figureData of heroldFiguresData) {
        // Check if the current item's name matches the given name
        if (listsContainSameEntries(figureData.names, name)) {
            // If match found, return the corresponding figure_type
            return figureData.figure_type;
        }
    }
    // Return null if no match found
    return null;
}

/**
 * Finds an attribute entry in the provided dataset that matches the given tags.
 * @param tags - The tag(s) to search for within the attribute names.
 * @param dataset - The dataset of attribute entries to search within.
 * @returns The attribute entry matching the given tags, or null if no match is found.
 * @template T - The type of data associated with the attribute entry.
 */
function findAttributeByTags<T>(tags: string[], dataset: AttributeEntry<T>[]): AttributeEntry<T>|null {
    let used: string[] = [];

    for (const attribute of dataset) {
        for(const name of attribute.names) {
            for(const tag of tags) {
                if (name === tag && !used.includes(tag)) {
                    used.push(tag);
                }
            }
        }

        if(listsContainSameEntries(attribute.names, used)) {
            return attribute;
        }

        used = [];
    }

    return null;
}

/**
 * Checks whether two arrays contain the same entries regardless of order.
 * @param list1 - The first array to compare.
 * @param list2 - The second array to compare.
 * @returns True if both arrays contain the same entries, false otherwise.
 */
function listsContainSameEntries(list1: string[], list2: string[]): boolean {
    // Sort both lists
    const sortedList1 = list1.slice().sort();
    const sortedList2 = list2.slice().sort();

    // Check if the sorted lists are equal
    if (sortedList1.length !== sortedList2.length) {
        return false;
    }

    for (let i = 0; i < sortedList1.length; i++) {
        if (sortedList1[i] !== sortedList2[i]) {
            return false;
        }
    }

    return true;
}

/**
 * Removes entries from listA that are also present in listB.
 * @param listA - The array from which entries will be removed.
 * @param listB - The array containing entries to be removed from listA.
 * @returns A new array with entries from listA that are not present in listB.
 */
function removeEntriesInListB(listA: string[], listB: string[]): string[] {
    const result = [...listA]; // Create a copy of listA

    for (const entry of listB) {
        const index = result.indexOf(entry);
        if (index !== -1) {
            result.splice(index, 1); // Remove one occurrence of the entry from listA
        }
    }

    return result;
}


export {findFigureTypeByName, findAttributeByTags, removeEntriesInListB};
