import {cleanStringSvg} from "../../utils/SvgUtils";
import {HeroldFigureTriangle} from "./Triangle";
import {HeroldFigureData} from "../../api/IBlazonApiHeroldFigures";

class HeroldFigureSpice extends HeroldFigureTriangle {

    getSimpleSpice(width: number, height: number, start_x: number, start_y: number): HeroldFigureData {
        // M 0 0 L 150 300 L 300 0 Z

        const PADDING_VERTICAL = 30;
        const PADDING_HORIZONTAL = 10;

        // Construct the SVG path using template literals
        const path = cleanStringSvg(`
            M ${start_x + width / 2} ${start_y}
            L ${start_x} ${height + start_y}
            L ${width + start_x} ${height + start_y}
            Z
        `);

        const CONSTANT = 5;

        const element_x_new = start_x + PADDING_HORIZONTAL + width/(CONSTANT);
        const element_y_new = Math.floor(height/(CONSTANT*0.5));

        const max_width = width - 2*element_x_new;
        const max_height = Math.floor(height/(CONSTANT*0.5));

        const svg = `<g><path d="${path}" /></g>`;


        return {width: width, height: height, svg: svg,
            element_x: element_x_new, element_y: element_y_new,
            element_max_width: max_width,element_max_height: max_height};
    }

    getFigure(): HeroldFigureData {
        return this.getSimpleSpice(this.width, this.height, this.start_x, this.start_y);
    }
}

export {HeroldFigureSpice};
