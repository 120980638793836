
/*
Colors was taken from https://cs.wikipedia.org/wiki/Seznam_barev
 */
export const czech_colors: ColorData[] = [
    {"name": "aqua", "color": "#00FFFF"},
    {"name": "světlá královsky modrá", "color": "#007FFF"},
    {"name": "béžová", "color": "#F5F5DC"},
    {"name": "bílá", "color": "#FFFFFF"},
    {"name": "bronzová", "color": "#CD7F32"},
    {"name": "burgundská", "color": "#900020"},
    {"name": "cyanová", "color": "#00FFFF"},
    {"name": "čokoládově hnědá", "color": "#7B3F00"},
    {"name": "černá", "color": "#000000"},
    {"name": "červená", "color": "#FF0000"},
    {"name": "ecru", "color": "#C2B280"},
    {"name": "egyptská modř", "color": "#1034A6"},
    {"name": "fialová", "color": "#8000FF"},
    {"name": "hnědá", "color": "#993300"},
    {"name": "indigová", "color": "#00416A"},
    {"name": "jadeová", "color": "#00A86B"},
    {"name": "jantarová", "color": "#FFBF00"},
    {"name": "karmínová", "color": "#DC143C"},
    {"name": "khaki", "color": "#C3B091"},
    {"name": "kobaltová", "color": "#0047AB"},
    {"name": "korálová", "color": "#FF7F50"},
    {"name": "krémová", "color": "#FFFDD0"},
    {"name": "lasturová", "color": "#FFF5EE"},
    {"name": "lesní zeleň", "color": "#228B22"},
    {"name": "levandulová", "color": "#E6E6FA"},
    {"name": "levandulová-zčervenalá", "color": "#FFF0F5"},
    {"name": "liláková", "color": "#C8A2C8"},
    {"name": "lněná", "color": "#FAF0E6"},
    {"name": "lososová", "color": "#FA8072"},
    {"name": "magenta", "color": "#FF00FF"},
    {"name": "mayská modř", "color": "#73C2FB"},
    {"name": "meruňková", "color": "#FBCEB1"},
    {"name": "modrá", "color": "#0000FF"},
    {"name": "muslimská zeleň", "color": "#009000"},
    {"name": "nachová", "color": "#D66984"},
    {"name": "navajová bílá", "color": "#FFDEAD"},
    {"name": "nebesky modrá", "color": "#87CEEB"},
    {"name": "námořní modř", "color": "#000080"},
    {"name": "okrová", "color": "#CC7722"},
    {"name": "olivová", "color": "#808000"},
    {"name": "oranžová", "color": "#FF6600"},
    {"name": "orchideová", "color": "#DA70D6"},
    {"name": "půlnoční modř", "color": "#003366"},
    {"name": "purpurová", "color": "#6A0DAD"},
    {"name": "rajčatová", "color": "#FF6347"},
    {"name": "rumělková", "color": "#E34234"},
    {"name": "růžová", "color": "#FFC0CB"},
    {"name": "šafránová", "color": "#F4C430"},
    {"name": "šarlatová", "color": "#FF2400"},
    {"name": "šedá", "color": "#808080"},
    {"name": "siena", "color": "#882D17"},
    {"name": "středně zelená", "color": "#008000"},
    {"name": "stříbrná", "color": "#C0C0C0"},
    {"name": "světle žlutá", "color": "#F0E68C"},
    {"name": "tmavě hnědá", "color": "#654321"},
    {"name": "tmavě modrá", "color": "#00008B"},
    {"name": "tmavě zelená", "color": "#013220"},
    {"name": "tyrkysová", "color": "#40E0D0"},
    {"name": "ultramarínová", "color": "#120A8F"},
    {"name": "vínová", "color": "#722F37"},
    {"name": "viridianová", "color": "#40826D"},
    {"name": "zelená", "color": "#008001"},
    {"name": "zlatá", "color": "#FFD700"},
    {"name": "žlutá", "color": "#FFFF00"}
]
