import {Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {parseSvgStyles, preprocessSvg, scaleRawSvg} from "../genblazon/utils/SvgUtils";
import {generalFiguresData} from "../genblazon/mock-data/general-figures/figures";
import {GeneralFigure, GeneralFigureEntry} from "../genblazon/api/IBlazonApiGeneralFigures";
import {BlazonApiGeneralFiguresMock} from "../genblazon/mock/BlazonApiGeneralFiguresMock";
import * as d3 from 'd3'; // Import D3 library

const blazonApiGeneralFigures = new BlazonApiGeneralFiguresMock();

const SvgGeneralFigures: React.FC = () => {
    const [figures, setFigures] = useState<GeneralFigureEntry[]>([]);

    useEffect(() => {
        const fetchFigures = async () => {
            try {
                const fetchedFigures = await blazonApiGeneralFigures.getAllFigures();
                setFigures(fetchedFigures);
            } catch (error) {
                console.error('Error fetching figures:', error);
            }
        };

        fetchFigures();
    }, []);

    return (
        <div style={{ padding: "40px" }}>
            <Typography variant="h1">Obecné Figury</Typography>
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}>
                {figures.map((figure, index) => {
                    // Render SVG with removed ids and classes
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index}
                              style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{
                                textAlign: 'center',
                                border: '1px solid #ccc',
                                padding: '10px',
                                borderRadius: '5px',
                                margin: '10px'
                            }}>
                                <div
                                    dangerouslySetInnerHTML={{__html: scaleRawSvg(preprocessSvg(figure.svg), 200, 200, 0, 0)}}/>
                                <div>
                                    <p style={{fontWeight: "bold"}}>Název figury:</p>
                                    <p>{"[" + figure.name.join(', ') + "]"}</p>
                                    <p style={{fontWeight: "bold"}}>Atributy figury:</p>
                                    <p>{"[" + figure.tags.join(', ') + "]"}</p>
                                </div>
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    )
        ;
};

export default SvgGeneralFigures;
