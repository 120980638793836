
/**
 * Represents a color object with an additional property.
 */
interface ColorWithTag extends Color {
    tag: string
}

/**
 * Finds the best matching color from a list of tags.
 * @param blazonApiColors The object containing the colors and their tags.
 * @param tags The array of tags to search for.
 * @returns The best matching color with its associated tag, or null if no suitable match is found.
 */
function findMatchColorFromTags(blazonApiColors: IBlazonApiColors,tags: string[]): ColorWithTag | null {
    let bestMatch: ColorWithTag | null = null;
    tags.forEach((tag: string) => {
        let curMatch: Color = blazonApiColors.getMatchColors(tag)[0];
        if(curMatch !== undefined) {
            if(bestMatch == null || (curMatch.distance < bestMatch.distance)) {
                bestMatch = {
                    ...curMatch,
                    tag: tag
                };
            }
        }
    });

    if(bestMatch !== null && bestMatch['distance'] < 3) {
        return bestMatch;
    } else {
        return null;
    }
}

export {findMatchColorFromTags};
