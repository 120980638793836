import React, {useEffect, useRef, useState} from 'react';
import {Theme, ThemeProvider} from "@mui/material/styles";


const PageWrapper = ({ children, header, footer, theme } : {children: any, header?: any, footer?: any, theme: Theme}) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
        if (footerRef.current) {
            setFooterHeight(footerRef.current.offsetHeight);
        }
    }, [headerRef, footerRef]);

    const wrapperHeight = `calc(100vh - ${headerHeight}px - ${footerHeight}px)`;

    return (
        <ThemeProvider theme={theme}>
            <div>
                {header && (
                    <div id="layout-header" ref={headerRef} className="header-wrapper">
                    {header}
                    </div>
                )}
                <div id="layout-wrapper" style={{ minHeight: wrapperHeight }}>
                    {children}
                </div>


                {footer && (
                    <div id="layout-footer" ref={footerRef} className="footer-wrapper">
                        {footer}
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default PageWrapper;
export { PageWrapper };
