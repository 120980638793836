import {HeroldFigureData} from "../api/IBlazonApiHeroldFigures";

/**
 * Interface representing an attribute entry.
 * @template T The type of the attribute entry.
 */
interface AttributeEntry<T> {
    names: string[],
    tag: T
}

/**
 * Interface representing a generator for Herold figures.
 */
interface HeroldFigureGenerator {
    /**
     * Return generated figure
     * @returns generated herold figure
     */
    getFigure(): HeroldFigureData
}

/**
 * Abstract class representing a Herold figure.
 * @abstract
 */
abstract class HeroldFigure implements HeroldFigureGenerator {
    /** The left top corner start x-coordinate of the empty space. */
    protected start_x: number;

    /** The left top corner start y-coordinate of the empty space. */
    protected start_y: number;

    /** max width of empty space */
    protected width: number;

    /** max height of empty space */
    protected height: number;

    /** herold figure tags */
    protected tagsAttributes: string[];

    /** wanted quantity of herold figures */
    protected quantity: number;

    constructor(start_x: number, start_y: number, width: number, height: number,
                          tags_attributes: string[], quantity: number) {
        this.start_x = start_x;
        this.start_y = start_y;
        this.width = width;
        this.height = height;
        this.tagsAttributes = tags_attributes;
        this.quantity = quantity;
    };

    abstract getFigure(): HeroldFigureData;
}

export {HeroldFigure, type HeroldFigureGenerator, type AttributeEntry};
