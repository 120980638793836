import {CBlazonField, CBlazonJson, CBlazonObject, CBlazonShield, CBlazonSplit} from "../../cblazon/cblazon_types";

/**
 * Represents the specific JSON structure for a blazon
 */
interface BlazonSpecificJson extends CBlazonJson {
    shield: ShieldSpecific,
}

/**
* Represents the specific JSON shield
*/
interface ShieldSpecific extends CBlazonShield {
    frame: FrameSpecific;
    splits: SplitSpecific;
    fields: FieldSpecific[];
}

/**
 * Represents the specific JSON split
 */
interface SplitSpecific extends CBlazonSplit {
    start_x: number,
    start_y: number,
    width: number,
    height: number
}

/**
 * Represents the specific JSON field
 */
interface FieldSpecific extends CBlazonField {
    objects: ObjectSpecific[];
    start_x: number,
    start_y: number,
    width: number,
    height: number,
    colourCode?: string,
    element_x: number,
    element_y: number,
    element_max_width: number,
    element_max_height: number
}

/**
 * Represents the specific JSON figure or object
 */
type ObjectSpecific = ObjectFigureGeneralSpecific | ObjectFigureHeroldSpecific | ObjectObjectSpecific;

/**
 * Represents the specific JSON general figure
 */
interface ObjectFigureGeneralSpecific extends CBlazonObject {
    figure_type: FigureType.GENERAL,
    obj_attrs: ObjectSpecific[],
    figureData: FigureDataSpecific[],
    figureIndex: number,
    colourCode?: string
}

/**
 * Represents the specific JSON herold figure
 */
interface ObjectFigureHeroldSpecific extends CBlazonObject {
    figure_type: FigureType.HEROLD,
    obj_attrs: ObjectSpecific[],
    element_x: number,
    element_y: number,
    element_max_width: number,
    element_max_height: number,
    height: number,
    width: number,
    svg: string,
    colourCode?: string,
}

/**
 * Represents the specific Object
 */
interface ObjectObjectSpecific extends CBlazonObject {
    obj_attrs: ObjectSpecific[],
    colourCode?: string,
}

/**
 * Represents the specific figure data entry
 */
interface FigureDataSpecific {
    name: string[],
    filename?: string,
    tags: string[],
    color_css_name: string,
    possible_objects: FigureDataPossibleObjSpecific[],
    source_url: string,
    distance?: number,
    svg: string
}

/**
 * Represents the specific possible objects on general figure entry
 */
interface FigureDataPossibleObjSpecific {
    name: string[],
    css_name: string,
    tags: string
}

/**
 * Represents the types of figures
 */
enum FigureType {
    GENERAL = "GENERAL",
    HEROLD = "HEROLD",
}

/**
 * Represents the JSON Specific frame of shield
 */
interface FrameSpecific {
    width: number;
    height: number;
    shieldPath: string;
}

export {type BlazonSpecificJson, type ObjectSpecific, type FieldSpecific, FigureType, type ObjectFigureHeroldSpecific, type ObjectFigureGeneralSpecific, type SplitSpecific}
