import {List, ListItem, Typography} from "@mui/material";
import TryCode from "../../../components/blazon-runner/TryCode";
import React from "react";
import {CblazonTestString} from "../../pages_types";


const exampleBasic: CblazonTestString = {
    text: "Ve zlatém štítě zelená pata.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "zlatý",
                    "objects": [
                        {
                            "name": [
                                "pata"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zelený"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleWidth: CblazonTestString = {
    text: "Ve zlatém štítě zelená zvýšená pata.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "zlatý",
                    "objects": [
                        {
                            "name": [
                                "pata"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zelený",
                                "zvýšený"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

function Pata() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Pata</Typography>

            <TryCode blazonText={exampleBasic.text} jsonBlazon={exampleBasic.jsonBlazon}></TryCode>

            <Typography variant="h2">Výška paty</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je pata v normální výšce
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>PATA_HEIGHT_SMALL = tag_attrs: [ "snížený" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>PATA_HEIGHT_LARGE = tag_attrs: [ "zvýšený" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>PATA_HEIGHT_NORMAL = nezadáno</ListItem>
            </List>
            <TryCode blazonText={exampleWidth.text} jsonBlazon={exampleWidth.jsonBlazon}></TryCode>

        </div>
    );
}

export default Pata;
