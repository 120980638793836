import {AttributeEntry} from "../types_HeroldFigure";

export enum HlavaHeight {
    NORMAL,
    SHORTER,
    LONGER,
}

export enum HlavaRotation {
    HORIZONTAL,
    DIAGONAL_KOSME,
    DIAGONAL_SIKME
}



export const HLAVA_DATA_HEIGHT: AttributeEntry<HlavaHeight>[] = [
    {
        names: ["zvýšený"],
        tag: HlavaHeight.LONGER
    },
    {
        names: ["vyvýšený"],
        tag: HlavaHeight.LONGER
    },
    {
        names: ["snížený"],
        tag: HlavaHeight.SHORTER
    },
    {
        names: ["poloviční"],
        tag: HlavaHeight.SHORTER
    },
    {
        names: [],
        tag: HlavaHeight.NORMAL
    },
]

export const HLAVA_DATA_ROTATION: AttributeEntry<HlavaRotation>[] = [
    {
        names: ["šikmý"],
        tag: HlavaRotation.DIAGONAL_SIKME
    },
    {
        names: ["kosmý"],
        tag: HlavaRotation.DIAGONAL_KOSME
    },
    {
        names: [],
        tag: HlavaRotation.HORIZONTAL
    },
]
