import {deepClone, removeStringFromArray} from "./utils/ObjectUtils";
import {BlazonFrames, FrameType} from "./BlazonFrames";
import split from "./split";
import {BlazonApiColorsMock} from "./mock/BlazonApiColorsMock";
import {BlazonApiGeneralFiguresMock} from "./mock/BlazonApiGeneralFiguresMock";
import {findMatchColorFromTags} from "./colorUtils";
import {CBlazonJson} from "../cblazon/cblazon_types";
import {BlazonApiHeroldFiguresMock} from "./mock/BlazonApiHeroldFigureMock";
import {BlazonSpecificJson, FigureType} from "./types/types_blazonSpecific";

const blazonApiColors = new BlazonApiColorsMock();
const blazonApiGeneralFigures = new BlazonApiGeneralFiguresMock();
const blazonApiHeroldFigures = new BlazonApiHeroldFiguresMock();

/**
 * Preprocesses the tree object fields by traversing recursively through the parent object's children.
 * Updates each child object with color information and determines if it is a special figure or a standard figure.
 *
 * @param parent - The parent object containing the children objects to preprocess.
 * @returns A Promise that resolves when preprocessing is complete.
 */
async function preprocessTreeObjectFields(parent: any): Promise<void> {
    // Recursively traverse children parents
    if (parent) {
        let parentObjects;
        if("obj_attrs" in parent && parent.obj_attrs && Array.isArray(parent.obj_attrs)) {
            parentObjects = parent.obj_attrs;
        } else if("objects" in parent && parent.objects && Array.isArray(parent.objects)) {
            parentObjects = parent.objects;
        } else {
            return;
        }

        for (const child of parentObjects) {
            /**
             * Find color
             */
            const colorAsTag = findMatchColorFromTags(blazonApiColors, child.tag_attrs);

            if(colorAsTag != null) {
                child.tag_attrs = removeStringFromArray(child.tag_attrs, colorAsTag.tag);
                child.colour = colorAsTag.tag;
                child.colourCode = colorAsTag.color;
            }

            /**
             * Find if is special figure
             */
            child.figure_type = FigureType.GENERAL;

            const heroldFigure = await blazonApiHeroldFigures.getMatchHeroldFigure(child.name, parent.start_x, parent.start_y, parent.width, parent.height, child.tag_attrs, child.quantity);

            if(heroldFigure != null) {
                child.figure_type = FigureType.HEROLD
                // child.figure_special_type = specialFigure;

                Object.assign(child, heroldFigure);
            } else {
                /**
                 * Find normal figure data
                 */

                const standardFigure = await blazonApiGeneralFigures.getMatchFigures(
                    child.name, child.tag_attrs, child.obj_attrs, child.natural_colours);

                if(standardFigure.length !== 0) {
                    child.figureData = standardFigure;
                    child.figureIndex = 0;
                }
            }

            const objAttributesArray = child.obj_attrs;

            if(objAttributesArray && Array.isArray(objAttributesArray) && objAttributesArray.length) {
                await preprocessTreeObjectFields(child);
            }
        }
    }
}


/**
 * Processes the given blazon JSON data to generate a specific JSON structure for rendering.
 *
 * @param blazonJsonData - The blazon JSON data to process.
 * @param width - The width of the blazon.
 * @param squareType - The type of square for the blazon frame.
 * @returns A Promise that resolves with the processed BlazonSpecificJson.
 */
async function processBlazonGenerateSpecificJson(blazonJsonData : CBlazonJson, width: number, squareType: FrameType): Promise<BlazonSpecificJson> {
    const extendedBlazonJson = deepClone(blazonJsonData);

    const blazon_frame = BlazonFrames.getSquareByType(squareType, width);
    const shield = extendedBlazonJson.shield;
    const fields = shield.fields;

    shield.frame = {};
    shield.frame = blazon_frame;

    split.calculateSplitsSizes(extendedBlazonJson.shield.splits, width, blazon_frame.height);

    const fieldsSplits = split.extractAndSortByFieldIx(shield.splits);

    const preprocessPromises = fields.map(async (field: any, index: number) => {
        /******************
         * Determine colors of field
         ******************/
        const czech_colour = field.colour;
        let color = "#FFFFFF";

        if(czech_colour !== undefined) {
            const colors = blazonApiColors.getMatchColors(czech_colour);

            if(colors[0] !== undefined) {
                color = colors[0].color;
            }
        }

        field.colourCode = color;

        const fieldSplit = fieldsSplits[index];

        const PADDING = 20;

        field.start_x = fieldSplit.start_x;
        field.start_y = fieldSplit.start_y;
        field.width = fieldSplit.width;
        field.height = fieldSplit.height;

        field.element_x = fieldSplit.start_x + PADDING;
        field.element_y = fieldSplit.start_y + PADDING;
        field.element_max_width = fieldSplit.width - 2*PADDING;
        field.element_max_height = fieldSplit.height - 2*PADDING;

        /******************
         * Iterate objects
         ******************/
        await preprocessTreeObjectFields(field);
    });

    await Promise.all(preprocessPromises);
    return extendedBlazonJson;
}

export { processBlazonGenerateSpecificJson};
