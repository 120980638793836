import {Button, Grid, TextField, Box, CircularProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import {cblazonApi} from "../../cblazon/cblazon_api";
import {FrameType} from "../../genblazon/BlazonFrames";
import { Editor } from "@monaco-editor/react";
import {genblazonDrawSvg, genblazonGenerateInternalJson} from "../../genblazon/genblazon";
import {CBlazonJson} from "../../cblazon/cblazon_types";
import {isValidCBlazonJson} from "../../cblazon/cblazon_valid_check";
import {makeStyles} from "@mui/styles";
import {BlazonSpecificJson} from "../../genblazon/types/types_blazonSpecific";

/**
 * Component for try blazon text. Component provide UI for generating blazon visualization
 * @param blazonText prefilled blazon text
 * @param jsonBlazon preprocessed json for blazon text (due to speedup first load)
 * @constructor
 */
function TryCode({blazonText = "", jsonBlazon} : {blazonText?: string, jsonBlazon: CBlazonJson}) {
    const [inputText, setInputText] = useState<string>(blazonText);
    const [jsonText, setJsonText] = useState<string>(JSON.stringify(jsonBlazon, null, 2));
    const [extendedJson, setExtendedJson] = useState<BlazonSpecificJson | null>(null);

    const [svgBlazon, setSvgBlazon] = useState<any | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    const styles = useStyles();

    /**
     * Runs on load
     * Generate initial visualisation
     */
    useEffect(() => {
        handleRedrawClick();
    }, []);

    /**
     * Handler for CBlazonJson changed text area editor input
     * @param newValue value after edit
     * @param event
     */
    const handleJsonInputChange = (newValue: string | undefined, event: any) => {
        if(newValue !== undefined) {
            setJsonText(newValue); // Update JSON text in state
        }
    };

    /**
     * Handle click whole generate process and their sequence
     */
    const handleGenerateClick = () => {

        if(!inputText) {
            return;
        }

        setIsLoading(true);

        // Process the input text using cblazonApi
        setTimeout(() => {

            cblazonApi.processAll(inputText)
                .then(result => {
                    setJsonText(JSON.stringify(result.json, null, 2));
                    if(result.error === 0) {
                        handleRedrawClick();
                        genblazonGenerateInternalJson(result.json, 250, FrameType.IBERIAN)
                            .then(result => {
                                setExtendedJson(result);
                                setSvgBlazon(genblazonDrawSvg(result));
                            })
                            .catch(error => {
                                console.error("Error generating SVG Blazon:", error);
                                setSvgBlazon(null);
                            });
                    }
                })
                .catch(error => {
                    console.error("Error:", error);
                    setJsonText("");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 100); // Adjust the delay time as needed
    };

    /**
     * Handle redraw click
     */
    function handleRedrawClick() {
        let jsonParsed: CBlazonJson;
        if(!jsonText) {
            return;
        }

        try {
            jsonParsed = JSON.parse(jsonText);
        } catch (e) {
            return;
        }

        if(!isValidCBlazonJson(jsonParsed)) {
            return;
        }

        genblazonGenerateInternalJson(jsonParsed, 250, FrameType.IBERIAN)
            .then(result => {
                setExtendedJson(result);
                setSvgBlazon(genblazonDrawSvg(result));
            })
            .catch(error => {
                console.error("Error generating SVG Blazon:", error);
                setSvgBlazon(null);
            });
    }

    /**
     * Handle change input text field
     * @param event input element change
     */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    return(
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
        >
            <Grid item xs={12} md={6}>
                <div>
                    <TextField
                        fullWidth
                        label="Blazon erbu"
                        placeholder={"Zadejte popis erbu ve formátu blazon"}
                        variant="outlined"
                        multiline
                        rows={4}
                        value={inputText}
                        onChange={handleInputChange}
                    />
                    <Box
                        className={styles.buttonContainer}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateClick}
                            disabled={isLoading}
                            sx={{ position: 'relative' }} // Add position relative to position CircularProgress
                        >
                            <Box
                                sx={{
                                    width: 100,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isLoading ? (
                                    <CircularProgress size={24} color="inherit" sx={{color: '#FFFFFF'}} />
                                ) : (
                                    "Generovat"
                                )}
                            </Box>
                        </Button>
                    </Box>

                </div>
                <div>
                    <div className={styles.editorContainer}>
                        <Editor
                            width="100%"
                            height="400px"
                            defaultLanguage="json"
                            value={jsonText}
                            onChange={handleJsonInputChange}
                        />
                    </div>
                    <Box
                        className={styles.buttonContainer}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRedrawClick}
                            children={"Vykreslit"}
                        />
                    </Box>

                </div>
            </Grid>
            <Grid item xs={12} md={6} >
                {svgBlazon && (
                    <div className={styles.svgContainer}
                         dangerouslySetInnerHTML={{__html: svgBlazon}}/>
                )}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme: any) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: 1,
        paddingTop: 15,
        paddingBottom: 15
    },
    editorContainer: {
        borderColor: "black",
        borderWidth: 1,
        borderStyle: "solid"
    },
    svgContainer: {
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '20px'
    }
}));

export default TryCode;
