import {List, ListItem, Typography} from "@mui/material";
import TryCode from "../../../components/blazon-runner/TryCode";
import React from "react";
import {CblazonTestString} from "../../pages_types";


const exampleBasic: CblazonTestString = {
    text: "Ve zlatém štítě černá špice.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "zlatý",
                    "objects": [
                        {
                            "name": [
                                "špice"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "černý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

function Spice() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Špice</Typography>

            <TryCode blazonText={exampleBasic.text} jsonBlazon={exampleBasic.jsonBlazon}></TryCode>

        </div>
    );
}

export default Spice;
