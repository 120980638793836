import {Box, Container, Typography} from "@mui/material";
import React from "react";
import TryCode from "../../components/blazon-runner/TryCode";
import {CblazonTestString} from "../pages_types";

const exampleBasic: CblazonTestString = {
    text: "V prvním bílém poli děleného a nahoře polceného štítu čtyři modré kůly. Ve druhém červeném poli bílý kříž. Ve třetím stříbrném poli s zeleným trávníkem hnědý kůň s růžovou zbrojí se zlatým ocasem.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "HORIZONTAL",
                "style": "dělený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "VERTICAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "upper",
                        "splits": [
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "right",
                                "field_ix": "0",
                                "splits": []
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "left",
                                "field_ix": "1",
                                "splits": []
                            }
                        ]
                    },
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "lower",
                        "field_ix": "2",
                        "splits": []
                    }
                ]
            },
            "fields": [
                {
                    "colour": "bílý",
                    "objects": [
                        {
                            "name": [
                                "kůl"
                            ],
                            "quantity": 4,
                            "tag_attrs": [
                                "modrý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "kříž"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "bílý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "stříbrný",
                    "objects": [
                        {
                            "name": [
                                "trávník"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zelený"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        },
                        {
                            "name": [
                                "kůň"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "hnědý"
                            ],
                            "obj_attrs": [
                                {
                                    "name": [
                                        "zbroj"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "růžový"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                },
                                {
                                    "name": [
                                        "ocas"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "zlatý"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                }
                            ],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}


const Overview = () => (
    <Container maxWidth="md">
        <Typography variant="h1">Přehled</Typography>
        <Typography variant="body1" sx={{paddingBottom: 10}}>
            Následující dokumentace obsahuje příklady a ukázky použití. Ukázky jsou rozděleny v levém navigačním menu.
        </Typography>


        <Typography variant="h2">Ukázka příkladu</Typography>

        <TryCode blazonText={exampleBasic.text} jsonBlazon={exampleBasic.jsonBlazon}></TryCode>
    </Container>

);

export default Overview;
