import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import {TreeItem2} from "@mui/x-tree-view";
import {makeStyles} from "@mui/styles";
import { MenuItem, Select} from '@mui/material';
import {preprocessSvg, scaleRawSvg} from "../../genblazon/utils/SvgUtils";

const useStyles = makeStyles((theme: any) => ({
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    title: {
        paddingRight: '15px',
    },
    divTreeItemFigure: {
        display: 'flex',
        alignItems: 'center',
    }
}));

interface HandleChangeTreeGeneralItemObject {
    handleColorChange: any;
    handleFigureChange: any;
}

function TreeItemGeneralFigure({ itemId, objFigure, objIndex, handleChange, children }:
                                  {itemId: any, objFigure: any, objIndex: number[], handleChange: HandleChangeTreeGeneralItemObject, children?: React.ReactNode }) {
    const style = useStyles();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const renderTitle = (): React.ReactNode => {
        return <span className={style.title}>{objFigure.name.join(".")}</span>;
    }

    const renderColor = (): React.ReactNode => {

        const stylesInline = ({
            color: {
                background: `${objFigure.colourCode}`,
            },
        });

        return (
            <div>
                <span className={style.title}>Color</span>

                <div className={style.swatch} onClick={handleClick}>
                    <div className={style.color} style={stylesInline.color}/>
                </div>

                {displayColorPicker && (
                    <div className={style.popover}>
                        <div className={style.cover} onClick={handleClose}></div>
                        <SketchPicker
                            color={objFigure.colourCode}
                            onChange={(color) => handleChange.handleColorChange(color, objIndex)}
                        />
                    </div>
                )}
            </div>

        )
    }

    const renderSelectFigure = (figures: any, selected: number): React.ReactNode => {

        // Calculate height based on aspect ratio
        const calculateHeight = (width: number) => {
            const aspectRatio = 1; // Example aspect ratio, adjust as needed
            return width * aspectRatio;
        };

        return (
            <div className={style.divTreeItemFigure}>
                <span className={style.title}>Výběr figury</span>

                <Select value={selected} onChange={(event) => handleChange.handleFigureChange(event.target.value, objIndex)}>
                    {figures.map((item: any, index: number) => (
                        <MenuItem key={index} value={index}>
                            <div
                                dangerouslySetInnerHTML={{ __html: scaleRawSvg(preprocessSvg(item.svg), 150, 150, 0, 0) }}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }

    return (
        <TreeItem2
            key={itemId}
            itemId={itemId}
            label={renderTitle()}
        >
            <TreeItem2 itemId={itemId + "-color"} label={renderColor()}/>
            <TreeItem2 itemId={itemId + "-select"} label={renderSelectFigure(objFigure.figureData, objFigure.figureIndex)}/>
            {children &&
                <TreeItem2 itemId={itemId + "-children"} label={"Childrens"}>
                    {children}
                </TreeItem2>
            }
        </TreeItem2>
    );
}


export {type HandleChangeTreeGeneralItemObject};

export default TreeItemGeneralFigure;
