import {List, ListItem, Typography} from "@mui/material";
import TryCode from "../../../components/blazon-runner/TryCode";
import React from "react";
import {CblazonTestString} from "../../pages_types";


const exampleBasic: CblazonTestString = {
    text: "V modrém štítě zlaté břevno.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": [
                        {
                            "name": [
                                "břevno"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zlatý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleWidth: CblazonTestString = {
    text: "V modrém štítě široké zlaté břevno.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                    "style": "",
                    "where": "CENTRE",
                    "place": "root",
                    "field_ix": "0",
                    "splits": []
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": [
                        {
                            "name": [
                                "břevno"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "široký",
                                "zlatý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
            "helmet": null,
            "valid": true
    }
}

const exampleQuantity: CblazonTestString = {
    text: "V modrém štítě pět zlatých břeven.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": [
                        {
                            "name": [
                                "břevno"
                            ],
                            "quantity": 5,
                            "tag_attrs": [
                                "zlatý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const examplePosition: CblazonTestString = {
    text: "V modrém štítě zvýšené zlaté břevno.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": [
                        {
                            "name": [
                                "břevno"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zvýšený",
                                "zlatý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleShorten: CblazonTestString = {
    text: "V modrém štítě zlaté oboustranně zkrácené břevno.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": [
                        {
                            "name": [
                                "břevno"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zlaté",
                                "zkrácený",
                                "oboustranně"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleRotation: CblazonTestString = {
    text: "V modrém štítě zlaté šikmé břevno.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": [
                        {
                            "name": [
                                "břevno"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zlatý",
                                "šikmý"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

function Brevno() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Břevno</Typography>
            <Typography variant="body1">
                Břevno je jedním ze základních heroldických figur. Šířka břevna není stanovena, obvykle šířka je, ale uvedena na jednu třetinu nebo dvě sedminy štítu.
            </Typography>

            <TryCode blazonText={exampleBasic.text} jsonBlazon={exampleBasic.jsonBlazon}></TryCode>


            <Typography variant="h2">Šířka břevna</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je břevno v normální šířce
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_WIDTH_SMALL = tag_attrs: [ "úzký" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_WIDTH_LARGE = tag_attrs: [ "široký" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_WIDTH_NORMAL = nezadáno</ListItem>
            </List>
            <TryCode blazonText={exampleWidth.text} jsonBlazon={exampleWidth.jsonBlazon}></TryCode>


            <Typography variant="h2">Více břeven</Typography>
            <Typography variant="body1">Element "quantity" vyjadřuje počet břeven v poli.</Typography>
            <TryCode blazonText={exampleQuantity.text} jsonBlazon={exampleQuantity.jsonBlazon}></TryCode>


            <Typography variant="h2">Pozice břevna</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je kůl ve středu pole.
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_POSITION_TOP = tag_attrs: [ "zvýšené" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_POSITION_BOTTOM = tag_attrs: [ "snížené" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_POSITION_CENTER = nezadáno</ListItem>
            </List>
            <TryCode blazonText={examplePosition.text} jsonBlazon={examplePosition.jsonBlazon}></TryCode>

            <Typography variant="h2">Zkrácené břevno</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je kůl v celé délce
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_SHORTEN_TOP = tag_attrs: [ "zkrácený", "vlevo" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_SHORTEN_BOTTOM = tag_attrs: [ "zkrácený", "vpravo" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_SHORTEN_BOTH = tag_attrs: [ "zkrácený", "oboustranně" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>KUL_SHORTEN_FULL = nezadáno</ListItem>
            </List>
            <TryCode blazonText={exampleShorten.text} jsonBlazon={exampleShorten.jsonBlazon}></TryCode>

            <Typography variant="h2">Šikmé břevno</Typography>
            <Typography variant="body1" paddingBottom="2px">
                Možnosti které musí být v elementu "tag_attrs". Pokud není zadáno je kůl v horizontální rotaci.
            </Typography>
            <List sx={{ listStyleType: 'disc', paddingBottom: "2em", paddingLeft: "4em" }}>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_ROTATION_SIKME = tag_attrs: [ "šikmý" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_ROTATION_KOSME = tag_attrs: [ "kosmý" ]</ListItem>
                <ListItem sx={{ display: 'list-item' }}>BREVNO_ROTATION_HORIZONTAL = nezadáno</ListItem>
            </List>
            <TryCode blazonText={exampleRotation.text} jsonBlazon={exampleRotation.jsonBlazon}></TryCode>

        </div>
    );
}

export default Brevno;
