import CBlazon from "./build-wasm/cblweb.js"
import {CBlazonData} from "./cblazon_types";

/**
 * Defines the type for the function returned by build-wasm.cwrap.
 */
type CBlazonProcessAll = (arg: string) => string;

/**
 * Define an interface for the API functions
 */
interface CBlazonApi {
    // Function to process JSON using the loaded build-wasm module
    processAll: (blazon_text: string) => Promise<CBlazonData>;
}

/**
 * Removes escape sequences from a given string.
 * @param inputString The input string containing escape sequences.
 * @returns A string with escape sequences removed.
 */
function removeEscapeSequences(inputString: string): string {
    return inputString.replace(/\\./g, '');
}

/**
 * Represents the implementation of the API functions.
 */
class CBlazonProcessor implements CBlazonApi {
    /** call function from ANSI C processJson */
    private blazonProcessAll: CBlazonProcessAll | null = null;

    constructor() {
        this.init();
    }

    /**
     * Initializes the CBlazonProcessor by loading the WebAssembly module. And bind functions
     */
    private async init() {
        const moduleConfiguration = {
            noInitialRun: true,
            locateFile: (path: string, scriptDirectory: string) => {
                // Prepend the root path to the filename
                return "/" + path;
            }
        }

        const blazon = await CBlazon(moduleConfiguration);

        // bind functions from ANSI C in WebAssembly
        this.blazonProcessAll = blazon.cwrap("cblazon_process", "string", ["string"]);
    }

    /**
     * Processes the blazon text and returns the parsed JSON.
     * @param {string} blazon_text - The blazon text to process.
     * @returns {Promise<CBlazonData>} A promise that resolves with the parsed JSON.
     */
    public async processAll(blazon_text: string): Promise<CBlazonData> {
        // Wait for initialization if blazonProcessJson is not yet initialized
        while (!this.blazonProcessAll) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100ms before checking again
        }

        // Now blazonProcessJson is initialized
        const processedJson = this.blazonProcessAll(blazon_text);
        const parsedJson = JSON.parse(processedJson);
        parsedJson.error = parseInt(parsedJson.error);
        if(parsedJson.error === 0) {
            parsedJson.json = JSON.parse(parsedJson.json);
        }

        parsedJson.tree_html = removeEscapeSequences(parsedJson.tree_html);

        return parsedJson;
    }
}

// Instantiate the CBlazonProcessor
const cblazonApi = new CBlazonProcessor();

// Export the API functions and types
export { type CBlazonApi, cblazonApi };
