import {AttributeEntry} from "../types_HeroldFigure";

export enum BrevnoStyle {
    NONE,
    PADDED_TOP_LEFT,
    PADDED_TOP_RIGHT,
    PADDED_BOTTOM_LEFT,
    PADDED_BOTTOM_RIGHT,
    GRADUALLY_LEFT,
    GRADUALLY_RIGHT,
}

export enum BrevnoWidth {
    NORMAL,
    SMALL,
    LARGE,
}

export enum BrevnoPosition {
    CENTER,
    UPPER,
    LOWER,
}

export enum BrevnoShorten {
    FULL,
    SHORTEN_LEFT,
    SHORTEN_RIGHT,
    SHORTED_BOTH,
}

export enum BrevnoTip {
    NONE,
    TRIANGLE_LEFT,
    TRIANGLE_RIGHT,
    TRIANGLE_BOTH,
}

export enum BrevnoRotation {
    HORIZONTAL,
    DIAGONAL_KOSME,
    DIAGONAL_SIKME
}


export const BREVNO_DATA_WIDTH: AttributeEntry<BrevnoWidth>[] = [
    {
        names: ["rozšířený"],
        tag: BrevnoWidth.LARGE
    },
    {
        names: ["široký"],
        tag: BrevnoWidth.LARGE
    },
    {
        names: ["zůžený"],
        tag: BrevnoWidth.SMALL
    },
    {
        names: ["úzký"],
        tag: BrevnoWidth.SMALL
    },
    {
        names: [],
        tag: BrevnoWidth.NORMAL
    },
]

export const BREVNO_DATA_POSITIONS: AttributeEntry<BrevnoPosition>[] = [
    {
        names: ["zvýšený"],
        tag: BrevnoPosition.UPPER
    },
    {
        names: ["snížený"],
        tag: BrevnoPosition.LOWER
    },
    {
        names: [],
        tag: BrevnoPosition.CENTER
    },
]


export const BREVNO_DATA_STYLES: AttributeEntry<BrevnoStyle>[] = [
    {
        names: ["odsazený", "nahoru", "vlevo"],
        tag: BrevnoStyle.PADDED_TOP_LEFT,
    },
    {
        names: ["odsazený", "nahoru", "vpravo"],
        tag: BrevnoStyle.PADDED_TOP_RIGHT,
    },
    {
        names: ["odsazený", "dolů", "vlevo"],
        tag: BrevnoStyle.PADDED_BOTTOM_LEFT,
    },
    {
        names: ["odsazený", "dolů", "vpravo"],
        tag: BrevnoStyle.PADDED_BOTTOM_RIGHT,
    },
    {
        names: ["stupňovitě", "vlevo"],
        tag: BrevnoStyle.GRADUALLY_LEFT,
    },
    {
        names: ["stupňovitě", "vpravo"],
        tag: BrevnoStyle.GRADUALLY_RIGHT,
    },
    {
        names: [],
        tag: BrevnoStyle.NONE,
    },
]

export const BREVNO_DATA_TIPS: AttributeEntry<BrevnoTip>[] = [
    {
        names: ["zahrocené", "zleva"],
        tag: BrevnoTip.TRIANGLE_LEFT,
    },
    {
        names: ["zahrocené", "zprava"],
        tag: BrevnoTip.TRIANGLE_RIGHT,
    },
    {
        names: ["zahrocené", "oboustranně"],
        tag: BrevnoTip.TRIANGLE_BOTH,
    },
    {
        names: [],
        tag: BrevnoTip.NONE
    },
]


export const BREVNO_DATA_SHORTEN: AttributeEntry<BrevnoShorten>[] = [
    {
        names: ["zkrácený", "zleva"],
        tag: BrevnoShorten.SHORTEN_LEFT,
    },
    {
        names: ["zkrácený", "zprava"],
        tag: BrevnoShorten.SHORTEN_RIGHT,
    },
    {
        names: ["zkrácený", "oboustranně"],
        tag: BrevnoShorten.SHORTED_BOTH,
    },
    {
        names: [],
        tag: BrevnoShorten.FULL,
    },
]

export const BREVNO_DATA_ROTATION: AttributeEntry<BrevnoRotation>[] = [
    {
        names: ["kosmý"],
        tag: BrevnoRotation.DIAGONAL_KOSME,
    },
    {
        names: ["kosmá"],
        tag: BrevnoRotation.DIAGONAL_KOSME,
    },
    {
        names: ["šikmý"],
        tag: BrevnoRotation.DIAGONAL_SIKME,
    },
    {
        names: ["šikmá"],
        tag: BrevnoRotation.DIAGONAL_SIKME,
    },
    {
        names: [],
        tag: BrevnoRotation.HORIZONTAL,
    },
]
