import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    TextField, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    genblazonGenerateInternalJson,
    genblazonDrawSvg
} from "../genblazon/genblazon";
import {cblazonApi} from "../cblazon/cblazon_api";
import {FrameType} from "../genblazon/BlazonFrames";
import "./Home.css"
import BlazonTreeView from "../components/blazon-treeview/BlazonTreeView";
import JsonSpecificEditor from "../components/blazon-editor-json-specific/JsonSpecificEditor";
import {CBlazonData, CBlazonJson} from "../cblazon/cblazon_types";
import {Editor} from "@monaco-editor/react";
import {isValidCBlazonJson} from "../cblazon/cblazon_valid_check";
import {BlazonSpecificJson} from "../genblazon/types/types_blazonSpecific";
import {CblazonTestString} from "./pages_types";

const prefilledDefaultValue: string = "V červeném štítě český lev se zlatou korunou.";


/**
 * Main page with entry insert blazon text
 * @constructor
 */
function Home() {
    const [inputText, setInputText] = useState<string>(prefilledDefaultValue);
    const [outputText, setOutputText] = useState<CBlazonData | null>();
    const [extendedJson, setExtendedJson] = useState<BlazonSpecificJson | null>(null);

    const [editorValue, setEditorValue] = useState<string>("");
    const [editorErrorMsg, setEditorErrorMsg] = useState<string>("");

    const [svgBlazon, setSvgBlazon] = useState<any | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCblazon, setIsLoadingCblazon] = useState(false);
    const [isLoadingFetching, setIsLoadingFetching] = useState(false);

    const [cblazonTimeProcessing, setCblazonTimeProcessing] = useState(0);
    const [showTree, setShowTree] = useState<boolean>(true);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    const handleProcessClick = () => {
        setIsLoading(true);
        setIsLoadingCblazon(true);
        setOutputText(null);
        setExtendedJson(null);
        setSvgBlazon(null);

        const startTime = performance.now(); // Capture start time

        // Process the input text using cblazonApi
        setTimeout(() => {
            cblazonApi.processAll(inputText)
                .then(result => {
                    setOutputText(result);
                    if(result.error === 0) {
                        setEditorValue(JSON.stringify(result.json, null, 2));
                        setEditorErrorMsg("");
                        handleGenerateSpecificJson(result.json);
                    }
                })
                .catch(error => {
                    setEditorErrorMsg("Error: " + error);
                    setOutputText(null);
                })
                .finally(() => {
                    const endTime = performance.now(); // Capture end time
                    const durationMilisec = endTime - startTime; // Calculate duration
                    const durationSec = Math.round((durationMilisec/1000 + Number.EPSILON) * 100) / 100;
                    setCblazonTimeProcessing(durationSec);

                    setIsLoading(false);
                    setIsLoadingCblazon(false);
                });
        }, 100); // Adjust the delay time as needed
    };

    const handleGenerateSpecificJson = (json: CBlazonJson) => {
        setIsLoadingFetching(true);
        genblazonGenerateInternalJson(json, 450, FrameType.IBERIAN)
            .then(result => {
                setExtendedJson(result);

                setSvgBlazon(genblazonDrawSvg(result));
            })
            .finally(() => {
                setIsLoadingFetching(false);
            });
    }

    const handleGenerateDrawSvg = () => {
        if(extendedJson != null) {
            setSvgBlazon(genblazonDrawSvg(extendedJson));
        }
    }

    const handleEditCBlazonJson = () => {
        try {
            const parsedJson = JSON.parse(editorValue);
            if(isValidCBlazonJson(parsedJson)) {
                handleGenerateSpecificJson(parsedJson);
                setEditorErrorMsg("");
            } else {
                setEditorErrorMsg("V datech chybí povinné vlastnosti")
            }
        } catch (e) {
            setEditorErrorMsg("Data nejsou ve formátu JSON")
        }
    };

    const handleDownloadSvg = () => {
        if(svgBlazon) {
            const svgBlob = new Blob([svgBlazon], { type: 'image/svg+xml' });
            const svgUrl = URL.createObjectURL(svgBlob);
            const downloadLink = document.createElement('a');
            downloadLink.href = svgUrl;
            downloadLink.download = 'heraldic_blazon.svg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    return (
    <div>
        <Container maxWidth="md">
            <Typography variant="h1">Generátor grafické vizualizace jazyka blazon</Typography>
            <Typography variant="body1">
                Následující program umožňuje generování obrázků heraldických znaků z heraldického popisu erbu nazývaného jako jazyk blazon. Hledáte-li popis erbu erb vaší obce/města můžete použít státní portál{' '}
                <a href="https://rekos.psp.cz" target="_blank" rel="noopener noreferrer">rekos.psp.cz</a>.
            </Typography>
        </Container>

        <Container maxWidth="md">
            <TextField
                fullWidth
                label="Blazon erbu"
                placeholder={"Zadejte popis erbu ve formátu blazon"}
                variant="outlined"
                multiline
                rows={4}
                value={inputText}
                onChange={handleInputChange}
            />
            <Box className="buttonContainer">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleProcessClick}
                    disabled={isLoading}
                >
                    Generovat
                </Button>
            </Box>

        </Container>

        {/* Output Text */}
        <Container maxWidth="md" className="containerBlazonSection">
            <Typography variant="h2">1. fáze - CBlazon</Typography>

            {/* Loading Cblazon */}
            {isLoadingCblazon && (
                <div className="loadingContainer">
                    <p>Processing by CBlazon ...</p>
                    <CircularProgress disableShrink />
                </div>
            )}


            {outputText && (
                <div>
                    <div style={{paddingTop: "30px"}}>
                        <Typography>Překlad trval: {cblazonTimeProcessing} sekund</Typography>
                    </div>

                    <div style={{paddingBottom: "30px"}}>
                        <BlazonTreeView content={outputText.tree_html} showTree={showTree} setShowTree={setShowTree}/>
                    </div>

                    { outputText.error === 0 ? (
                        <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    blazon ve formátu JSON
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="jsonEditorContainer">
                                        <Editor
                                            width="100%"
                                            height="600px"
                                            defaultLanguage="json"
                                            value={editorValue}
                                            onChange={(value) => {
                                                if(value !== undefined) {
                                                    setEditorValue(value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <Box className="buttonContainer">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleEditCBlazonJson}
                                            children={"Zpracovat a překreslit"}
                                        />
                                    </Box>
                                </AccordionDetails>

                                {editorErrorMsg && ( // Conditionally render error message
                                    <Alert variant="filled" severity="error">
                                        {editorErrorMsg}
                                    </Alert>
                                )}
                            </Accordion>
                        </div>
                    ) : (
                        <Alert variant="filled" severity="error">
                            {outputText.error_msg}
                        </Alert>
                    )}
                </div>
            )}
        </Container>

        <Container maxWidth="md" className="containerBlazonSection">
            <Typography variant="h2">2. fáze - Stažení dat k erbu</Typography>

            {/* Loading Fetching data */}
            {isLoading && (
                <div className="loadingContainer">
                    {isLoadingFetching ? (
                        <p>Fetching data ...</p>
                    ) : (
                        <p>Waiting for input data ...</p>
                    )}

                    <CircularProgress disableShrink />
                </div>
            )}

            {extendedJson ? (
                <>
                    <div>
                        <JsonSpecificEditor jsonSpecificData={extendedJson} setSpecificData={setExtendedJson} />
                    </div>

                    {/*
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            JSON Extended format
                        </AccordionSummary>
                        <AccordionDetails>
                            <pre>{JSON.stringify(extendedJson, null, 2)}</pre>
                        </AccordionDetails>
                    </Accordion>
                    */}

                    <Box className="buttonContainer">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateDrawSvg}
                            children="Vykreslit"
                        />
                    </Box>
                </>
            ) : (
                <p></p>
            )}
        </Container>

        <Container maxWidth="md" className="containerBlazonSection">
            <Typography variant="h2">3. fáze - Vykreslení erbu</Typography>

            {svgBlazon && (
                <>
                    <div className={"svgContainer"} dangerouslySetInnerHTML={{__html: svgBlazon}}/>

                    <Box className="buttonContainer">
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadSvg}
                            disabled={!svgBlazon}
                            children="Stáhnout SVG"
                        />
                    </Box>
                </>
            )}

        </Container>
    </div>
    );
}

export default Home;
