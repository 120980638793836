import {AttributeEntry} from "../types_HeroldFigure";

export enum KulWidth {
    NORMAL,
    SMALL,
    LARGE,
}

export enum KulShorten {
    FULL,
    SHORTEN_TOP,
    SHORTEN_BOTTOM,
    SHORTED_BOTH,
}

export enum KulPosition {
    CENTER,
    LEFT,
    RIGHT
}

export enum KulStyle {
    NONE,
    PADDED_TOP_LEFT,
    PADDED_TOP_RIGHT,
    PADDED_BOTTOM_LEFT,
    PADDED_BOTTOM_RIGHT,
    GRADUALLY_LEFT,
    GRADUALLY_RIGHT,
}

export enum KulTip {
    NONE,
    TRIANGLE_TOP,
    TRIANGLE_BOTTOM,
    TRIANGLE_BOTH,
}


// sirku lze take nahradit vyrazy prut, nit, lať ale nemely by se pouzivat
// protoze se pak nahradi klicovym slovem kůl
export const KUL_DATA_WIDTH: AttributeEntry<KulWidth>[] = [
    {
        names: ["rozšířený"],
        tag: KulWidth.LARGE
    },
    {
        names: ["široký"],
        tag: KulWidth.LARGE
    },
    {
        names: ["zůžený"],
        tag: KulWidth.SMALL
    },
    {
        names: ["úzký"],
        tag: KulWidth.SMALL
    },
    {
        names: ["kolík"],
        tag: KulWidth.SMALL
    },
    {
        names: ["prut"],
        tag: KulWidth.SMALL
    },
    {
        names: ["lať"],
        tag: KulWidth.SMALL
    },
    {
        names: ["nit"],
        tag: KulWidth.SMALL
    },
    {
        names: [],
        tag: KulWidth.NORMAL
    },
]

export const KUL_DATA_POSITIONS: AttributeEntry<KulPosition>[] = [
    {
        names: ["vlevo"],
        tag: KulPosition.LEFT
    },
    {
        names: ["vpravo"],
        tag: KulPosition.RIGHT
    },
    {
        names: [],
        tag: KulPosition.CENTER
    },
]

export const KUL_DATA_STYLES: AttributeEntry<KulStyle>[] = [
    {
        names: ["odsazený", "nahoře", "vlevo"],
        tag: KulStyle.PADDED_TOP_LEFT,
    },
    {
        names: ["odsazený", "nahoře", "vpravo"],
        tag: KulStyle.PADDED_TOP_RIGHT,
    },
    {
        names: ["odsazený", "dole", "vlevo"],
        tag: KulStyle.PADDED_BOTTOM_LEFT,
    },
    {
        names: ["odsazený", "dole", "vpravo"],
        tag: KulStyle.PADDED_BOTTOM_RIGHT,
    },
    {
        names: ["stupňovitě", "vlevo"],
        tag: KulStyle.GRADUALLY_LEFT,
    },
    {
        names: ["stupňovitě", "vpravo"],
        tag: KulStyle.GRADUALLY_RIGHT,
    },
    {
        names: [],
        tag: KulStyle.NONE
    },
]

export const KUL_DATA_TIPS: AttributeEntry<KulTip>[] = [
    {
        names: ["zahrocený", "nahoře"],
        tag: KulTip.TRIANGLE_TOP,
    },
    {
        names: ["zahrocený", "dole"],
        tag: KulTip.TRIANGLE_BOTTOM,
    },
    {
        names: ["zahrocený", "oboustranně"],
        tag: KulTip.TRIANGLE_BOTH,
    },
    {
        names: [],
        tag: KulTip.NONE
    },
]

export const KUL_DATA_SHORTEN: AttributeEntry<KulShorten>[] = [
    {
        names: ["zkrácený", "dole"],
        tag: KulShorten.SHORTEN_BOTTOM,
    },
    {
        names: ["zkrácený", "nahoře"],
        tag: KulShorten.SHORTEN_TOP,
    },
    {
        names: ["zkrácený", "oboustranně"],
        tag: KulShorten.SHORTED_BOTH,
    },
    {
        names: [],
        tag: KulShorten.FULL,
    },
]
