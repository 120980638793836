import {GeneralFigure} from "../../api/IBlazonApiGeneralFigures";

export const generalFiguresData: GeneralFigure[] = [
    {
        name: [ "orlice" ],
        filename: "animals/eagle/orlice_plamenna.svg",
        tags: [ "plamenný", "přemyslovská" ],
        color_css_name: "barva",
        possible_objects: [
            {
                name: [ "zbroj" ],
                css_name: "zbroj",
                tags: []
            },
            {
                name: [ "plamen" ],
                css_name: "plameny",
                tags: []
            },
            {
                name: [ "jazyk" ],
                css_name: "jazyk",
                tags: []
            },
            {
                name: [ "zobák" ],
                css_name: "zobak",
                tags: []
            },
            {
                name: [ "pařát" ],
                css_name: "paraty",
                tags: []
            },
            {
                name: [ "dráp" ],
                css_name: "paraty",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:P%C5%99emyslovci_erb.svg",
    },
    {
        name: [ "univerzita" ],
        filename: "zcu_logo.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [],
        source_url: "https://cs.wikipedia.org/wiki/Soubor:Westb%C3%B6hmische_Universit%C3%A4t_Pilsen_Logo.svg",
    },
    {
        name: [ "lev" ],
        filename: "animals/lion/czech_lion.svg",
        tags: [
            "český"
        ],
        color_css_name: "srst",
        possible_objects: [
            {
                name: [ "zbroj" ],
                css_name: "zbroj",
                tags: []
            },
            {
                name: [ "zub" ],
                css_name: "zuby",
                tags: []
            },
            {
                name: [ "dráp" ],
                css_name: "drapy",
                tags: []
            },
            {
                name: [ "koruna" ],
                css_name: "koruna",
                tags: []
            },
            {
                name: [ "jazyk" ],
                css_name: "jazyk",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Lion_from_small_coat_of_arms_of_the_Czech_Republic.svg",
    },
    {
        name: [ "růže" ],
        filename: "organic/rose/rose_czech_town_bavorov.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
            {
                name: [ "semeník" ],
                css_name: "semenik",
                tags: []
            },
            {
                name: ["lístek"],
                css_name: "list",
                tags: [ "kališní" ]
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Coa_Czech_Town_Bavorov.svg",
    },
    {
        name: [ "lilie" ],
        filename: "organic/fleur_de_lis/plant_lili.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [],
        source_url: "https://commons.wikimedia.org/wiki/File:Coa_Illustration_Elements_Plant_Lily.svg",
    },
    {
        name: [ "orlice" ],
        filename: "animals/eagle/coa_orlice_moravian.svg",
        tags: [ "moravská" ],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "zbroj",
                tags: []
            },
            {
                name: ["jazyk"],
                css_name: "jazyk",
                tags: []
            },
            {
                name: ["zobák"],
                css_name: "zobak",
                tags: []
            },
            {
                name: ["pařát"],
                css_name: "paraty",
                tags: []
            },
            {
                name: ["koruna"],
                css_name: "koruna",
                tags: []
            },
            {
                name: ["dráp"],
                css_name: "paraty",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Znak_Moravy.svg",
    },
    {
        name: [ "orlice" ],
        filename: "animals/eagle/coa_slezska_orlice.svg",
        tags: [ "slezká" ],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "zbroj",
                tags: []
            },
            {
                name: ["jazyk"],
                css_name: "jazyk",
                tags: []
            },
            {
                name: ["zobák"],
                css_name: "zobak",
                tags: []
            },
            {
                name: ["pařát"],
                css_name: "paraty",
                tags: []
            },
            {
                name: ["dráp"],
                css_name: "paraty",
                tags: []
            },
            {
                name: ["koruna"],
                css_name: "koruna",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Znak_Slezska.svg",
    },
    {
        name: [ "lev" ],
        filename: "animals/lion/Lion_Dormant.svg",
        tags: [ "spící" ],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "drapy",
                tags: []
            },
            {
                name: ["dráp"],
                css_name: "drapy",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Lion_Dormant.svg",
    },
    {
        name: [ "lev" ],
        filename: "animals/lion/Lion_Couchant.svg",
        tags: [ "ležící" ],
        color_css_name: "barva",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "drapy",
                tags: []
            },
            {
                name: ["zub"],
                css_name: "zuby",
                tags: []
            },
            {
                name: ["jazyk"],
                css_name: "jazyk",
                tags: []
            },
            {
                name: ["dráp"],
                css_name: "drapy",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Lion_Couchant.svg",
    },
    {
        name: ["jelen"],
        filename: "animals/deer/Letohrad_CoA_CZ.svg",
        tags: ["vykračující"],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["paroh"],
                css_name: "parohy",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Letohrad_CoA_CZ.svg",
    },
    {
        name: ["býk"],
        filename: "animals/bull/Heraldique_meubleTaureau_example.svg",
        tags: [""],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["skok"],
                css_name: "",
                tags: []
            },
            {
                name: ["zbroj"],
                css_name: "zbroj",
                tags: []
            },
            {
                name: ["ocas"],
                css_name: "ocas",
                tags: []
            },
            {
                name: ["kopyto"],
                css_name: "kopyta",
                tags: []
            },
            {
                name: ["roh"],
                css_name: "rohy",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Heraldique_meubleTaureau_example.svg",
    },
    {
        name: ["býk"],
        filename: "animals/bull/Heraldique_meubleTaureau_passant.svg",
        tags: ["kráčející"],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "rohy",
                tags: []
            },
            {
                name: ["roh"],
                css_name: "rohy",
                tags: []
            },
            {
                name: ["ocas"],
                css_name: "ocas",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Heraldique_meubleTaureau_passant.svg",
    },
    {
        name: ["panna", "Marie"],
        filename: "religion/virgin_mary/CoA_Mary.svg",
        tags: [],
        color_css_name: "",
        possible_objects: [
            {
                name: ["plášť"],
                css_name: "",
                tags: []
            },
            {
                name: ["roucho"],
                css_name: "",
                tags: []
            },
            {
                name: ["závoj"],
                css_name: "",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:CoA_Mary.svg",
    },
    {
        name: ["fontána"],
        filename: "architecture/fontain/Heraldique_fontaine.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Heraldique_fontaine.svg",
    },
    {
        name: ["pavilon"],
        filename: "architecture/pavillion/russian_pavilon.svg",
        tags: [],
        color_css_name: "fasada",
        possible_objects: [
            {
                name: ["okno"],
                css_name: "okno",
                tags: []
            },
            {
                name: ["kříž"],
                css_name: "kriz",
                tags: []
            },
            {
                name: ["střecha"],
                css_name: "strecha",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:%D0%93%D0%B5%D1%80%D0%B1_%D0%9F%D0%B5%D1%87%D0%B5%D0%BD%D0%B3%D0%B0.svg",
    },
    {
        name: ["kříž"],
        filename: "symbols/cross/Greek_cross.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Greek_cross.svg",
    },
    {
        name: ["kříž"],
        filename: "symbols/cross/Cross-Pattee-Heraldry.svg",
        tags: ["templářský"],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Maltese-Cross-Heraldry.svg",
    },
    {
        name: ["kříž"],
        filename: "symbols/cross/Patriarchal_or_Archbishop_Cross.svg",
        tags: ["dvojramenný"],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Patriarchal_or_Archbishop_Cross.svg",
    },
    {
        name: ["podkova"],
        filename: "symbols/horseshoe/podkova.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:H%C3%A9raldique_meuble_Fer_%C3%A0_cheval.svg",
    },
    {
        name: ["sekera"],
        filename: "symbols/axe/Beardaxe.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Beardaxe.svg",
    },
    {
        name: ["meč"],
        filename: "symbols/sword/sword_francoiz.svg",
        tags: [],
        color_css_name: "rukojet",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Beardaxe.svg",
    },
    {
        name: ["kůň"],
        filename: "animals/horse/horse_walking.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "zbroj",
                tags: []
            },
            {
                name: ["hříva"],
                css_name: "hriva",
                tags: []
            },
            {
                name: ["ocas"],
                css_name: "hriva",
                tags: []
            }
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Beardaxe.svg",
    },
    {
        name: ["strom"],
        filename: "organic/tree/tree_listnaty.svg",
        tags: ["listnatý"],
        color_css_name: "",
        possible_objects: [
            {
                name: ["kořen"],
                css_name: "",
                tags: []
            }
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Arms_of_the_house_of_Serbelloni_(ancient).svg",
    },
    {
        name: ["věž"],
        filename: "architecture/tower/tower.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Blason_de_la_famille_de_Belcastel.svg",
    },
    {
        name: ["hrad"],
        filename: "architecture/castle/castle.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Arms_of_the_house_of_Andreani.svg",
    },
    {
        name: ["slunce"],
        filename: "nature/sun/sun.svg",
        tags: [],
        color_css_name: "barva",
        possible_objects: [
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:H%C3%A9raldique_meuble_Soleil_avec_visage.svg",
    },
    {
        name: [ "lev" ],
        filename: "animals/lion/Lion_Sejant.svg",
        tags: [ "sedící" ],
        color_css_name: "srst",
        possible_objects: [
            {
                name: ["zbroj"],
                css_name: "zbroj",
                tags: []
            },
            {
                name: ["dráp"],
                css_name: "drapy",
                tags: []
            },
            {
                name: ["ocas"],
                css_name: "ocas",
                tags: []
            },
            {
                name: ["zub"],
                css_name: "zuby",
                tags: []
            },
        ],
        source_url: "https://commons.wikimedia.org/wiki/File:Lion_Sejant.svg",
    },
]
