
/**
 * Creates a deep clone of the given object.
 * @param obj The object to be cloned.
 * @returns A deep clone of the given object.
 */
function deepClone(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
        // If obj is not an object, return it as is
        return obj;
    }

    // Create an empty object to store the cloned properties
    const clonedObj: any = Array.isArray(obj) ? [] : {};

    // Iterate over each property of obj
    for (const key in obj) {
        // Check if the property is an own property (not inherited)
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // Recursively deep clone nested objects
            clonedObj[key] = deepClone(obj[key]);
        }
    }

    return clonedObj;
}

/**
 * Removes a specified string from an array.
 * @param array The array from which the string will be removed.
 * @param keyToRemove The string to be removed from the array.
 * @returns The array with the specified string removed.
 */
function removeStringFromArray(array: string[], keyToRemove: string) {
    return array.filter(item => item !== keyToRemove);
}


/**
 * Checks whether two arrays have the same content.
 * @param arr1 The first array to compare.
 * @param arr2 The second array to compare.
 * @returns A boolean indicating whether the arrays have the same content.
 */
function arraysHaveSameContent<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
        return false; // If lengths are different, arrays cannot have the same content
    }

    // Create copies of the arrays to avoid mutating the original arrays
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    // Compare the sorted arrays
    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false; // If any element at the same index is different, arrays don't have the same content
        }
    }

    return true; // If all elements match, arrays have the same content
}


/**
 * Calculates the distance between two arrays based on their content.
 * @param arr1 The first array.
 * @param arr2 The second array.
 * @returns The distance between the arrays based on their content.
 */
function calcDistanceArraysHaveSameContent<T>(arr1: T[], arr2: T[]): number {

    // Create copies of the arrays to avoid mutating the original arrays
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    let distance = 0;

    const smallerLength = Math.min(sortedArr1.length, sortedArr2.length);
    const biggerLength = Math.max(sortedArr1.length, sortedArr2.length);
    distance += biggerLength - smallerLength;

    // Compare the sorted arrays
    for (let i = 0; i < smallerLength; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            distance += 5;
        }
    }

    return distance;
}


export {deepClone, removeStringFromArray, arraysHaveSameContent, calcDistanceArraysHaveSameContent};
