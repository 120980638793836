import {cleanStringSvg} from "../../utils/SvgUtils";
import {HeroldFigure} from "../types_HeroldFigure";
import {HeroldFigureData} from "../../api/IBlazonApiHeroldFigures";


abstract class HeroldFigureDelitel extends HeroldFigure {

    protected getSimpleRectangle(start_center_x: number, start_center_y: number,
                                end_center_x:number, end_center_y: number,
                                width: number): HeroldFigureData {
        // M 0 0 L 0 600 L 250 600 L 250 0 Z

        // vector from 2 points from start_center to end_center
        const vector_start_center_x = end_center_x - start_center_x;
        const vector_start_center_y = end_center_y - start_center_y;

        // vector from 2 points from end_center to start center
        const vector_end_center_x = start_center_x - end_center_x;
        const vector_end_center_y = start_center_y - end_center_y;

        // height of rectangle determined by euclid distance
        const height = Math.sqrt(Math.pow(vector_start_center_x, 2) + Math.pow(vector_start_center_y, 2));

        // making vector_start_center unit vector
        const s_unit_x = vector_start_center_x / height;
        const s_unit_y = vector_start_center_y / height;

        // making vector_end_center unit vector
        const e_unit_x = vector_end_center_x / height;
        const e_unit_y = vector_end_center_y / height;

        // sizing unit vector to needed size
        const s_x = s_unit_x * width/2;
        const s_y = s_unit_y * width/2;

        // sizing unit vector to needed size
        const e_x = e_unit_x * width/2;
        const e_y = e_unit_y * width/2;

        // coordinates points p1 a p4
        const p1_x = start_center_x - s_y;
        const p1_y = start_center_y + s_x;

        const p4_x = end_center_x - s_y;
        const p4_y = end_center_y + s_x;

        // coordinates points p2 a p3
        const p2_x = end_center_x - e_y;
        const p2_y = end_center_y + e_x;

        const p3_x = start_center_x - e_y;
        const p3_y = start_center_y + e_x;

        const element_x = Math.min(p1_x, p2_x, p3_x, p4_x);
        const element_max_x = Math.max(p1_x, p2_x, p3_x, p4_x);
        const element_y = Math.min(p1_y, p2_y, p3_y, p4_y);
        const element_max_y = Math.max(p1_y, p2_y, p3_y, p4_y);


        // Construct the SVG path using template literals
        const path = cleanStringSvg(`
            M ${p1_x} ${p1_y}
            L ${p4_x} ${p4_y}
            L ${p2_x} ${p2_y}
            L ${p3_x} ${p3_y}
            Z
        `);

        const svg = `<g><path d="${path}" /></g>`;

        return {
            element_max_height: element_max_y - element_y,
            element_max_width: element_max_x - element_x,
            element_x: element_x,
            element_y: element_y,
            height: this.height,
            width: this.width,
            svg: svg
        };
    }

    protected getRectangleWithDelitels(start_center_x: number, start_center_y: number,
                                 end_center_x:number, end_center_y: number,
                                       width_all: number, quantity: number, coeficientWidth: number): HeroldFigureData {

        const width = (width_all / ((quantity * 2) + 1));

        // vector from 2 points from start_center to end_center
        const vector_start_center_x = end_center_x - start_center_x;
        const vector_start_center_y = end_center_y - start_center_y;

        // vector from 2 points from end_center to start center
        const vector_end_center_x = start_center_x - end_center_x;
        const vector_end_center_y = start_center_y - end_center_y;

        // height of rectangle determined by euclid distance
        const height = Math.sqrt(Math.pow(vector_start_center_x, 2) + Math.pow(vector_start_center_y, 2));

        // making vector_start_center unit vector
        const s_unit_x = vector_start_center_x / height;
        const s_unit_y = vector_start_center_y / height;

        // making vector_end_center unit vector
        const e_unit_x = vector_end_center_x / height;
        const e_unit_y = vector_end_center_y / height;

        // sizing unit vector to needed size
        const s_x = s_unit_x * width_all/2;
        const s_y = s_unit_y * width_all/2;

        // sizing unit vector to needed size
        const e_x = e_unit_x * width_all/2;
        const e_y = e_unit_y * width_all/2;

        const left_start_x = start_center_x - s_y;
        const left_start_y = start_center_y + s_x;

        const unit_left_s_x = (start_center_x - left_start_x) / (width_all / 2);
        const unit_left_s_y = (start_center_y - left_start_y) / (width_all / 2);

        const left_end_x = end_center_x + e_y;
        const left_end_y = end_center_y - e_x;

        const unit_left_e_x = (end_center_x - left_end_x) / (width_all / 2);
        const unit_left_e_y = (end_center_y - left_end_y) / (width_all / 2);

        let out_svg = "<g>";

        for(let i = 1; i <= quantity *2; i += 2) {
            const cur_start_x = left_start_x + ((i * width + width/2) * unit_left_s_x);
            const cur_start_y = left_start_y + ((i * width + width/2) * unit_left_s_y);

            const cur_end_x = left_end_x + ((i * width + width/2) * unit_left_e_x);
            const cur_end_y = left_end_y + ((i * width + width/2) * unit_left_e_y);

            const rect = this.getSimpleRectangle(cur_start_x, cur_start_y, cur_end_x, cur_end_y, coeficientWidth * width);
            out_svg += rect.svg;
        }

        out_svg += "</g>";

        return {
            element_max_height: this.height,
            element_max_width: this.width,
            element_x: this.start_x,
            element_y: this.start_y,
            height: this.height,
            width: this.width,
            svg: out_svg
        };
    }

}

export {HeroldFigureDelitel};
