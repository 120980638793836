
import {List, ListItem, Typography} from "@mui/material";
import TryCode from "../../components/blazon-runner/TryCode";
import React from "react";
import {CblazonTestString} from "../pages_types";


const exampleDeleni: CblazonTestString = {
    text: "V prvním modře děleném štítě. Ve druhém zlatém poli.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "HORIZONTAL",
                "style": "dělený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "upper",
                        "field_ix": "0",
                        "splits": []
                    },
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "lower",
                        "field_ix": "1",
                        "splits": []
                    }
                ]
            },
            "fields": [
                {
                    "colour": "modrý",
                    "objects": []
                },
                {
                    "colour": "zlatý",
                    "objects": []
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const examplePolceni: CblazonTestString = {
    text: "V prvním stříbrném poli polceného štítu. Ve druhém červeném poli. ",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "VERTICAL",
                "style": "polcený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "right",
                        "field_ix": "0",
                        "splits": []
                    },
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "left",
                        "field_ix": "1",
                        "splits": []
                    }
                ]
            },
            "fields": [
                {
                    "colour": "stříbrný",
                    "objects": []
                },
                {
                    "colour": "červený",
                    "objects": []
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleCombination: CblazonTestString = {
    text: "V prvním stříbrném poli polceného a vlevo děleného a nahoře děleného štítu. Ve druhém červeném poli. Ve třetím modrém poli. Ve čtvrtém purpurovém poli.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "VERTICAL",
                "style": "polcený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "right",
                        "field_ix": "2",
                        "splits": []
                    },
                    {
                        "type": "HORIZONTAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "left",
                        "splits": [
                            {
                                "type": "HORIZONTAL",
                                "style": "",
                                "where": "CENTRE",
                                "place": "upper",
                                "splits": [
                                    {
                                        "type": "UNSPLIT",
                                        "style": "",
                                        "where": "CENTRE",
                                        "place": "upper",
                                        "field_ix": "0",
                                        "splits": []
                                    },
                                    {
                                        "type": "UNSPLIT",
                                        "style": "",
                                        "where": "CENTRE",
                                        "place": "lower",
                                        "field_ix": "1",
                                        "splits": []
                                    }
                                ]
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "lower",
                                "field_ix": "3",
                                "splits": []
                            }
                        ]
                    }
                ]
            },
            "fields": [
                {
                    "colour": "stříbrný",
                    "objects": []
                },
                {
                    "colour": "červený",
                    "objects": []
                },
                {
                    "colour": "modrý",
                    "objects": []
                },
                {
                    "colour": "purpurový",
                    "objects": []
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleCtvrceny: CblazonTestString = {
    text: "",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "VERTICAL",
                "style": "polcený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "HORIZONTAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "right",
                        "splits": [
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "upper",
                                "field_ix": "3",
                                "splits": []
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "lower",
                                "field_ix": "2",
                                "splits": []
                            }
                        ]
                    },
                    {
                        "type": "HORIZONTAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "left",
                        "splits": [
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "upper",
                                "field_ix": "1",
                                "splits": []
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "lower",
                                "field_ix": "0",
                                "splits": []
                            }
                        ]
                    }
                ]
            },
            "fields": [
                {
                    "colour": "zelený",
                    "objects": []
                },
                {
                    "colour": "růžový",
                    "objects": []
                },
                {
                    "colour": "zlatý",
                    "objects": []
                },
                {
                    "colour": "šedý",
                    "objects": []
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}


function Splitting() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Dělení štítu</Typography>

            <Typography variant="h2">Polcení</Typography>

            <TryCode blazonText={examplePolceni.text} jsonBlazon={examplePolceni.jsonBlazon}></TryCode>

            <Typography variant="h2">Dělení</Typography>

            <TryCode blazonText={exampleDeleni.text} jsonBlazon={exampleDeleni.jsonBlazon}></TryCode>

            <Typography variant="h2">Kombinace dělení</Typography>

            <TryCode blazonText={exampleCombination.text} jsonBlazon={exampleCombination.jsonBlazon}></TryCode>

            <Typography variant="h2">Čtvrcený štít</Typography>

            <TryCode blazonText={exampleCtvrceny.text} jsonBlazon={exampleCtvrceny.jsonBlazon}></TryCode>
        </div>
    );
}

export default Splitting;

