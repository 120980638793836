
/**
 * Enums of herold types of figures.
 */
enum HeroldFigureType {
    KLIN = "KLIN",
    KUL = "KUL",
    BREVNO = "BREVNO",
    PATA = "PATA",
    PATA_TRAVNIK = "TRAVNIK",
    HLAVA = "HLAVA",
    SPICE = "SPICE"
}

export {HeroldFigureType};
