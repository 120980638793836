import {CblazonTestString} from "../../pages_types";
import {Typography} from "@mui/material";
import TryCode from "../../../components/blazon-runner/TryCode";


const exampleCzechSmallCoa: CblazonTestString = {
    text: "V červeném štítě český lev se zlatou korunou.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "lev"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "český"
                            ],
                            "obj_attrs": [
                                {
                                    "name": [
                                        "koruna"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "zlatý"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                }
                            ],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleCzechLargeCoa: CblazonTestString = {
    text: "",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "VERTICAL",
                "style": "polcený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "HORIZONTAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "right",
                        "splits": [
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "upper",
                                "field_ix": "3",
                                "splits": []
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "lower",
                                "field_ix": "2",
                                "splits": []
                            }
                        ]
                    },
                    {
                        "type": "HORIZONTAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "left",
                        "splits": [
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "upper",
                                "field_ix": "1",
                                "splits": []
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "lower",
                                "field_ix": "0",
                                "splits": []
                            }
                        ]
                    }
                ]
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "lev"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "český"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "světlá královsky modrá",
                    "objects": [
                        {
                            "name": [
                                "orlice"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "moravská"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "zlatý",
                    "objects": [
                        {
                            "name": [
                                "orlice"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "slezká",
                                "černá"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "lev"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "český"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

function GovermentCzech() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Znaky České Republiky</Typography>

            <Typography variant="h2">Malý státní znak</Typography>

            <TryCode blazonText={exampleCzechSmallCoa.text} jsonBlazon={exampleCzechSmallCoa.jsonBlazon}></TryCode>

            <Typography variant="h2">Velký státní znak</Typography>

            <TryCode blazonText={exampleCzechLargeCoa.text} jsonBlazon={exampleCzechLargeCoa.jsonBlazon}></TryCode>

        </div>
    );
}

export default GovermentCzech;

