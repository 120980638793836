/**
 * Represents a function that calculates the distance between two strings.
 */
interface StringDistanceFunc {
    (s1: string, s2: string): number;
}

/**
 * Calculates the Hamming distance between two strings.
 * @param s1 The first string.
 * @param s2 The second string.
 * @returns The Hamming distance between the two strings.
 */
function hammingDistance(s1: string, s2: string): number {
    const maxLength = Math.max(s1.length, s2.length);
    let distance = 0;

    // filling shorter string if the lenght is not same
    const paddedS1 = s1.padEnd(maxLength, '0');
    const paddedS2 = s2.padEnd(maxLength, '0');

    // Comparing char by char
    for (let i = 0; i < maxLength; i++) {
        // if not same adding distance value
        if (paddedS1[i] !== paddedS2[i]) {
            distance++;
        }
    }

    return distance;
}

/**
 * Calculates the Levenshtein distance between two strings.
 * @param s1 The first string.
 * @param s2 The second string.
 * @returns The Levenshtein distance between the two strings.
 */
function levenshteinDistance(s1: string, s2: string): number {
    const len1 = s1.length;
    const len2 = s2.length;

    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    // creating internal small results
    const matrix: number[][] = [];

    // initialization of matrix
    for (let i = 0; i <= len1; i++) {
        matrix[i] = [i];
    }
    for (let j = 0; j <= len2; j++) {
        matrix[0][j] = j;
    }

    // Algorithm levenshtein Distance
    for (let i = 1; i <= len1; i++) {
        for (let j = 1; j <= len2; j++) {
            const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
            matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1,     // deletion
                matrix[i][j - 1] + 1,           // adding
                matrix[i - 1][j - 1] + cost     // replacing
            );
        }
    }

    // distance
    return matrix[len1][len2];
}

export {type StringDistanceFunc, hammingDistance, levenshteinDistance};
