
/**
 * Generates a sorting function for sorting objects in ascending order based on a text property.
 * @param getTextProperty A function that retrieves the text property from an object.
 * @returns A sorting function for sorting objects in ascending order based on the specified text property.
 */
const byTextAscending = <T>(getTextProperty: (object: T) => String) => (objectA: T, objectB: T) => {
    const upperA = getTextProperty(objectA).toLowerCase();
    const upperB = getTextProperty(objectB).toLowerCase();
    if (upperA < upperB) {
        return -1;
    }
    if (upperA > upperB) {
        return 1;
    }
    return 0;
};

/**
 * Generates a sorting function for sorting objects in ascending order based on a numerical property.
 * @param getNumberProperty A function that retrieves the numerical property from an object.
 * @returns A sorting function for sorting objects in ascending order based on the specified numerical property.
 */
const byNumberAscending = <T>(getNumberProperty: (object: T) => number) => (objectA: T, objectB: T) => {
    return getNumberProperty(objectA) - getNumberProperty(objectB);
};


export {byNumberAscending, byTextAscending};
