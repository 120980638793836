import {czech_colors} from "../mock-data/colors/colors";
import {levenshteinDistance} from "../utils/StringDistance";
import {findEntriesWithLowestDistance} from "../utils/StringDistanceUtils";

/**
 * Represents a mock implementation of the interface for managing colors data
 */
class BlazonApiColorsMock implements IBlazonApiColors {
    getMatchColors(czechName: string): Color[] {
        let output: Color[] = [];

        if (czechName === undefined) {
            return output;
        }

        output = findEntriesWithLowestDistance(czech_colors, item => item.name, czechName, levenshteinDistance, 3);

        return output;
    }

    getAllColors(): ColorData[]{
        return czech_colors;
    }

}

export { BlazonApiColorsMock };
