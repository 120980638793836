import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import HeaderNavbar from "./components/header-navbar/HeaderNavbar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import themeHeraldic from "./theme/theme_heraldic";
import PageWrapper from "./components/page-wrapper/PageWrapper";
import PageContentSimple from "./components/page-wrapper/PageContentSimple";
import Documentation from "./pages/Documentation";

function App() {
    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
        if (footerRef.current) {
            setFooterHeight(footerRef.current.offsetHeight);
        }
    }, [headerRef, footerRef]);

    return (
        <BrowserRouter>
            <PageWrapper theme={themeHeraldic} header={<div ref={headerRef}><HeaderNavbar/></div>}>
                <Routes>
                    <Route path="/" element={<PageContentSimple additionalHeight={headerHeight + footerHeight}><Home /></PageContentSimple>} />
                    <Route path="/documentation/*" element={<Documentation />} />
                    <Route path="/about" element={<PageContentSimple additionalHeight={headerHeight + footerHeight}><About /></PageContentSimple>} />
                </Routes>
            </PageWrapper>
        </BrowserRouter>
    );
}

export default App;
