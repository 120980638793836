import {cleanStringSvg} from "../../utils/SvgUtils";
import {HeroldFigureTriangle} from "./Triangle";
import {HeroldFigureData} from "../../api/IBlazonApiHeroldFigures";

class HeroldFigureKlin extends HeroldFigureTriangle {

    getSimpleKlin(width: number, height: number, start_x: number, start_y: number): HeroldFigureData {
        // M 0 0 L 150 300 L 300 0 Z

        const PADDING_VERTICAL = 30;
        const PADDING_HORIZONTAL = 10;

        // Construct the SVG path using template literals
        const path = cleanStringSvg(`
            M ${start_x} ${start_y}
            L ${width/2 + start_x} ${height + start_y}
            L ${width + start_x} ${start_y}
            Z
        `);

        const CONSTANT = 5;

        const element_x = start_x + PADDING_HORIZONTAL + width/(CONSTANT);
        const element_y = start_y + PADDING_VERTICAL;

        const max_width = width - 2*element_x;
        const max_height =Math.floor(height/(CONSTANT*0.5));

        const svg = `<g><path d="${path}" /></g>`;


        return {width: width, height: height, svg: svg,
            element_x: element_x, element_y: element_y,
            element_max_width: max_width,element_max_height: max_height};
    }

    getFigure(): HeroldFigureData {
        //return this.getTriangleSimple(this.start_x, this.start_y, this.start_x + this.width/2, this.start_y + this.height, this.start_x + this.width, this.start_y);
        return this.getSimpleKlin(this.width, this.height, this.start_x, this.start_y);
    }
}

export {HeroldFigureKlin};
