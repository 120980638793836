import {BlazonApiColorsMock} from "../../genblazon/mock/BlazonApiColorsMock";
import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";


const blazonApiColors = new BlazonApiColorsMock();

const ColorsList: React.FC = () => {
    const [colors, setColors] = useState<ColorData[]>([]);

    useEffect(() => {
        setColors(blazonApiColors.getAllColors());
    }, []);

    return (
        <div style={{ padding: "40px" }}>
            <Typography variant="h1">Tinktury</Typography>

            <Typography>
                V heraldice se jednotlivé „barvy“ nazývají tinktury, ty se dělí na barvy, kovy a kožešiny. Z důvodu zjednodušení je aplikace schopná vykreslit pouze barvy a kovy. Uvedený seznam barev obsahuje více barev než ty, které se v heraldice běžně používají.
            </Typography>

            <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
                {colors.map((color, index) => {
                        // Render SVG with removed ids and classes
                        return (
                            <Grid item xs={12} sm={4} md={3} key={index} style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "200px", // Set a fixed width for the color block
                                    height: "140px", // Set a fixed height for the color block
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    margin: "10px",
                                }}>
                                    <div
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            backgroundColor: color.color,
                                            borderWidth: "1px",
                                            borderColor: "#7e7e7e",
                                            borderStyle: "solid",
                                            borderRadius: "5px",
                                            marginBottom: "10px",
                                        }}
                                    />

                                    <div style={{textAlign: "center"}}>
                                        <p>{color.name}</p>
                                    </div>
                                </div>
                            </Grid>
                        );
                })}
            </Grid>
        </div>
    )
        ;
};

export default ColorsList;
