import {cleanStringSvg} from "./utils/SvgUtils";

/**
 * Interface representing the properties of a blazon frame.
 */
interface BlazonFrame {
    /** The width of the blazon frame. */
    width: number;
    /** The height of the blazon frame. */
    height: number;
    /** SVG path data for the shield. */
    shieldPath: string;
}

/**
 * Enum representing different types of blazon frames.
 */
enum FrameType {
    IBERIAN,
    FRENCH,
    HEATER
}

/**
 * Class containing static methods to generate various types of blazon frames.
 */
class BlazonFrames {


    /**
     * Get a square blazon frame of the specified type and width.
     * @param type The type of blazon frame.
     * @param width The width of the blazon frame.
     * @returns The blazon frame object.
     */
    static getSquareByType(type: FrameType, width: number) {
        switch (type) {
            case FrameType.IBERIAN:
                return this.getSquareIberian(width);
            case FrameType.FRENCH:
                return this.getSquareFrench(width);
            case FrameType.HEATER:
                return this.getHeater(width);
        }
    }

    /**
     * Get a French-style square blazon frame with the specified width.
     * @param width The width of the blazon frame.
     * @returns The French-style blazon frame object.
     */
    static getSquareFrench(width: number): BlazonFrame {
        const height = width * 1.14;

        // m 0 0 v 155 c 10 60 60 120 125 130 c 70 -10 120 -70 125 -130 V 0 z

        const middle_width = width * 0.5;
        const param1 = width * 0.24;
        const param2 = width * 0.48;
        const param3 = width * 0.28;
        const param4 = width * 0.52;
        const param6 = width * 0.04;

        // Construct the SVG path using template literals
        const frame_path = `
            m 0 0
            v ${width * 0.62}
            c ${param6} ${param1} ${param1} ${param2} ${middle_width} ${param4}
            c ${param3} ${-param6} ${param2} ${-param3} ${middle_width} ${-param4}
            V 0
            z
        `;
        return { width: width, height: height, shieldPath: cleanStringSvg(frame_path) };
    }

    /**
     * Get a Heater-style square blazon frame with the specified width.
     * @param width The width of the blazon frame.
     * @returns The Heater-style blazon frame object.
     */
    static getHeater(width: number): BlazonFrame {
        const height = width * 1.16;

        // m 0 0 v 70 C 0 220 110 280 130 290 C 150 280 250 220 250 70 V 0 z

        const mid_height = width * 0.28;
        const nwm1 = width * 0.88;
        const nwm2 = width * 1.12

        // Construct the SVG path using template literals
        const frame_path = `
            m 0 0
            v ${mid_height}
            C 0 ${nwm1} ${width * 0.44} ${nwm2} ${width * 0.52} ${height}
            C ${width * 0.6} ${nwm2} ${width} ${nwm1} ${width} ${mid_height}
            V 0
            z
        `;
        return { width: width, height: height, shieldPath: cleanStringSvg(frame_path) };
    }

    /**
     * Get an Iberian-style square blazon frame with the specified width.
     * @param width The width of the blazon frame.
     * @returns The Iberian-style blazon frame object.
     */
    static getSquareIberian(width: number): BlazonFrame {
        const height = width*1.2;
        const frame_path =`
            M 0 ${width * 0.7}
            L 0 0
            L ${width} 0
            L ${width} ${width * 0.7}
            A ${width * 0.5} ${width * 0.5}, 0, 0, 1, 0 ${width * 0.7}
        `;
        return { width: width, height: height, shieldPath: cleanStringSvg(frame_path) };
    }

}

export { BlazonFrames, type BlazonFrame, FrameType };
