import {CblazonTestString} from "../../pages_types";
import React from "react";
import TryCode from "../../../components/blazon-runner/TryCode";
import {Typography} from "@mui/material";


const exampleLetohrad: CblazonTestString = {
    text: "V červeném štítě na zeleném trávníku vykračující jelen přirozené barvy.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "trávník"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zelený"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        },
                        {
                            "name": [
                                "jelen"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "vykračující"
                            ],
                            "obj_attrs": [],
                            "natural_colours": true
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleVrazOkresPisek: CblazonTestString = {
    text: "V červeném štítě stříbrný klín v něm černá plamenná orlice.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "červený",
                    "objects": [
                        {
                            "name": [
                                "klín"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "stříbrný"
                            ],
                            "obj_attrs": [
                                {
                                    "name": [
                                        "orlice"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "černý",
                                        "plamenný"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                }
                            ],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleBavorov: CblazonTestString = {
    text: "Ve stříbrném štítě červená růže se zlatým semeníkem a zelenými kališními lístky.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "UNSPLIT",
                "style": "",
                "where": "CENTRE",
                "place": "root",
                "field_ix": "0",
                "splits": []
            },
            "fields": [
                {
                    "colour": "stříbrný",
                    "objects": [
                        {
                            "name": [
                                "růže"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "červený"
                            ],
                            "obj_attrs": [
                                {
                                    "name": [
                                        "semeník"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "zlatý"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                },
                                {
                                    "name": [
                                        "lístek"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "zelený",
                                        "kališní"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                }
                            ],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

const exampleMarianskeLazne: CblazonTestString = {
    text: "V prvním zlatém poli děleného a nahoře polceného štítu čelně vyrůstající Panna Marie v červeném rouše s modrým pláštěm a stříbrným závojem. Ve druhém poli stříbrný kulatý pavilon se čtyřmi černými románskými okny a červenou kuželovou vydutou střechou a zlatým patriarším křížem. Ve třetím stříbrném poli s zeleným trávníkem stříbrná fontána.",
    jsonBlazon: {
        "shield": {
            "splits": {
                "type": "HORIZONTAL",
                "style": "dělený",
                "where": "CENTRE",
                "place": "root",
                "splits": [
                    {
                        "type": "VERTICAL",
                        "style": "",
                        "where": "CENTRE",
                        "place": "upper",
                        "splits": [
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "right",
                                "field_ix": "0",
                                "splits": []
                            },
                            {
                                "type": "UNSPLIT",
                                "style": "",
                                "where": "CENTRE",
                                "place": "left",
                                "field_ix": "1",
                                "splits": []
                            }
                        ]
                    },
                    {
                        "type": "UNSPLIT",
                        "style": "",
                        "where": "CENTRE",
                        "place": "lower",
                        "field_ix": "2",
                        "splits": []
                    }
                ]
            },
            "fields": [
                {
                    "colour": "zlatý",
                    "objects": [
                        {
                            "name": [
                                "panna",
                                "Marie"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "vyrůstající"
                            ],
                            "obj_attrs": [
                                {
                                    "name": [
                                        "roucho"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "červený"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                },
                                {
                                    "name": [
                                        "plášť"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "modrý"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                },
                                {
                                    "name": [
                                        "závoj"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "stříbrný"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                }
                            ],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "",
                    "objects": [
                        {
                            "name": [
                                "pavilon"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "stříbrný",
                                "kulatý"
                            ],
                            "obj_attrs": [
                                {
                                    "name": [
                                        "okno"
                                    ],
                                    "quantity": 4,
                                    "tag_attrs": [
                                        "černý",
                                        "románský"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                },
                                {
                                    "name": [
                                        "střecha"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "červený",
                                        "kuželový",
                                        "vydutý"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                },
                                {
                                    "name": [
                                        "kříž"
                                    ],
                                    "quantity": 1,
                                    "tag_attrs": [
                                        "zlatý",
                                        "patriarší"
                                    ],
                                    "obj_attrs": [],
                                    "natural_colours": false
                                }
                            ],
                            "natural_colours": false
                        }
                    ]
                },
                {
                    "colour": "stříbrný",
                    "objects": [
                        {
                            "name": [
                                "trávník"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "zelený"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        },
                        {
                            "name": [
                                "fontána"
                            ],
                            "quantity": 1,
                            "tag_attrs": [
                                "stříbrný"
                            ],
                            "obj_attrs": [],
                            "natural_colours": false
                        }
                    ]
                }
            ]
        },
        "bearer": null,
        "helmet": null,
        "valid": true
    }
}

function Towns1() {

    return(
        <div style={{padding: "40px"}}>
            <Typography variant="h1">Znaky měst</Typography>

            <Typography variant="h2">Letohrad</Typography>

            <TryCode blazonText={exampleLetohrad.text} jsonBlazon={exampleLetohrad.jsonBlazon}></TryCode>

            <Typography variant="h2">Vráž - okres Písek</Typography>

            <TryCode blazonText={exampleVrazOkresPisek.text} jsonBlazon={exampleVrazOkresPisek.jsonBlazon}></TryCode>

            <Typography variant="h2">Bavorov</Typography>

            <TryCode blazonText={exampleBavorov.text} jsonBlazon={exampleBavorov.jsonBlazon}></TryCode>

            <Typography variant="h2">Marianské lázně</Typography>

            <TryCode blazonText={exampleMarianskeLazne.text} jsonBlazon={exampleMarianskeLazne.jsonBlazon}></TryCode>
        </div>
    );
}

export default Towns1;

