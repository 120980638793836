import {Container, Typography} from "@mui/material";
import React from "react";


const About = () => (
    <Container maxWidth="md">
        <Typography variant="h1">O aplikaci</Typography>
        <Typography variant="body1">
            Aplikace vznikla na katedře informatiky, která je součástí fakulty Aplikovaných věd Západočeské univerzity v Plzni.
        </Typography>
    </Container>
);

export default About;
