import {findAttributeByTags, removeEntriesInListB} from "./utils";
import {
    KUL_DATA_POSITIONS,
    KUL_DATA_SHORTEN,
    KUL_DATA_STYLES,
    KUL_DATA_TIPS,
    KUL_DATA_WIDTH,
    KulPosition,
    KulShorten,
    KulStyle,
    KulTip,
    KulWidth
} from "./KulData";
import {HeroldFigureDelitel} from "./Delitel";
import {HeroldFigureData} from "../../api/IBlazonApiHeroldFigures";


class HeroldFigureKul extends HeroldFigureDelitel {

    static ATTR_DATASETS = [KUL_DATA_STYLES, KUL_DATA_SHORTEN, KUL_DATA_TIPS, KUL_DATA_POSITIONS, KUL_DATA_WIDTH];

    private attrStyle: KulStyle;
    private attrShorten: KulShorten;
    private attrTip: KulTip;
    private attrPosition: KulPosition;
    private attrWidth: KulWidth;

    constructor(start_x: number, start_y: number, width: number, height: number,
                tags_attributes: string[], quantity: number) {
        super(start_x, start_y, width, height, tags_attributes, quantity);

        this.attrStyle = KulStyle.NONE;
        this.attrShorten = KulShorten.FULL;
        this.attrTip = KulTip.NONE;
        this.attrPosition = KulPosition.CENTER;
        this.attrWidth = KulWidth.NORMAL;

        let freeAttributes: string[] = [ ...this.tagsAttributes ]

        for (let i = 0; i < HeroldFigureKul.ATTR_DATASETS.length; i++) {
            const dataset = HeroldFigureKul.ATTR_DATASETS[i];
            const attr = findAttributeByTags<any>(freeAttributes, dataset);

            if (attr !== null) {
                freeAttributes = removeEntriesInListB(freeAttributes, attr.names);

                switch (i) {
                    case 0:
                        this.attrStyle = attr.tag;
                        break;
                    case 1:
                        this.attrShorten = attr.tag;
                        break;
                    case 2:
                        this.attrTip = attr.tag;
                        break;
                    case 3:
                        this.attrPosition = attr.tag;
                        break;
                    case 4:
                        this.attrWidth = attr.tag;
                        break;
                    default:
                        throw new Error("Unexpected index in SpecialFigureKul.ATTR_DATASETS");
                }
            }
        }
    }

    public getFigure(): HeroldFigureData {
        let widthRect = 200;
        let height = this.height;

        let start_center_x = this.start_x  + (this.width / 2);
        let start_center_y = this.start_y;

        let end_center_x = start_center_x;
        let end_center_y = start_center_y + height;

        let start_offset_y = 0;


        switch (this.attrShorten) {
            case KulShorten.FULL:
                height = this.height;
                break;
            case KulShorten.SHORTEN_TOP:
                height = Math.floor(5*this.height / 6);
                start_offset_y = this.height - height;
                break;
            case KulShorten.SHORTEN_BOTTOM:
                height = Math.floor(5*this.height / 6);
                start_offset_y = 0;
                break;
            case KulShorten.SHORTED_BOTH:
                height = Math.floor(3*this.height / 4);
                start_offset_y = (this.height - height) / 2;
                break;
            default:
                throw new Error("Unexpected index in SpecialFigureKul.getFigure");
        }

        if(this.quantity <= 1) {
            switch (this.attrWidth) {
                case KulWidth.NORMAL:
                    widthRect = Math.floor(this.width / 4);
                    break;
                case KulWidth.LARGE:
                    widthRect = Math.floor(this.width / 2);
                    break;
                case KulWidth.SMALL:
                    widthRect = Math.floor(this.width / 8);
                    break
                default:
                    throw new Error("Unexpected index in SpecialFigureKul.getFigure");
            }

            switch (this.attrPosition) {
                case KulPosition.CENTER:
                    start_center_x = this.start_x  + (this.width / 2);
                    start_center_y = this.start_y + start_offset_y;

                    end_center_x = start_center_x;
                    end_center_y = start_center_y + height;
                    break;
                case KulPosition.RIGHT:
                    start_center_x = this.start_x  + (this.width / 4);
                    start_center_y = this.start_y + start_offset_y;

                    end_center_x = start_center_x;
                    end_center_y = start_center_y + height;
                    break;
                case KulPosition.LEFT:
                    start_center_x = this.start_x  + (3* this.width / 4);
                    start_center_y = this.start_y + start_offset_y;

                    end_center_x = start_center_x;
                    end_center_y = start_center_y + height;
                    break
                default:
                    throw new Error("Unexpected index in SpecialFigureKul.getFigure");
            }

        } else {

        }

        if(this.quantity <= 1) {
            return this.getSimpleRectangle(start_center_x, start_center_y,
                end_center_x, end_center_y,
                widthRect);
        } else {
            return this.getRectangleWithDelitels(start_center_x, start_center_y,
                end_center_x, end_center_y,
                this.width, this.quantity, 1);
        }
    }

}

export {HeroldFigureKul};
