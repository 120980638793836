import React from 'react';
import { makeStyles } from '@mui/styles';
import {Theme} from "@mui/material/styles";

const WRAPPER_PADDING_TOP = "40px"
const WRAPPER_PADDING_BOTTOM = "40px"

const CONTENT_PADDING_TOP = "40px"
const CONTENT_PADDING_BOTTOM = "40px"

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        margin: 0,
        padding: 0,
        paddingTop: WRAPPER_PADDING_TOP,
        paddingBottom: WRAPPER_PADDING_BOTTOM,
        [theme.breakpoints.down('md')]: {
            background: 'none', // Remove background on mobile
        },
        backgroundColor: '#f0f0f0',
        // background: 'url("/img/body_background_line.gif") repeat',
    },
    content: {
        maxWidth: '950px', // Example max-width for content
        margin: '0 auto', // Center content horizontally
        backgroundColor: '#ffffff',
        paddingTop: CONTENT_PADDING_TOP,
        paddingBottom: CONTENT_PADDING_BOTTOM,
        [theme.breakpoints.down('md')]: {
            borderStyle: 'none', // Remove on mobile
            borderWidth: 'none',
            borderColor: 'none',
        },
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#b2b2b2",
    },
}));

const PageContentSimple = ({ children, additionalHeight } : {children: any, additionalHeight?: number}) => {
    const classes = useStyles();

    const contentHeight = `calc(100vh - ${additionalHeight}px - 2*${WRAPPER_PADDING_TOP} - 2*${WRAPPER_PADDING_BOTTOM})`;

    return (
        <div className={classes.wrapper}>
            <div className={classes.content} style={{ minHeight: contentHeight }}>
                {children}
            </div>
        </div>
    );
};

export default PageContentSimple;
