import {StringDistanceFunc} from "./StringDistance";
import {byNumberAscending} from "./SortInterface";

/**
 * Represents an entry containing the distance and index of a dataset item.
 */
interface DistanceEntry {
    distance: number;
    index: number;
}

/**
 * Finds entries with the lowest distance compared to the input text using a specified string distance function.
 *
 * @param datasetArray - The array of dataset items to search through.
 * @param getFieldProperty - A function to extract the property value from each dataset item.
 * @param inputText - The input text to compare against.
 * @param stringDistanceFunc - The string distance function to use for comparison.
 * @param maxReturnedEntries - The maximum number of closest matches to return (default: 3).
 * @returns An array of entries with the lowest distances, or null if the dataset array is not valid.
 */
function findEntriesWithLowestDistance(datasetArray: any,
                                       getFieldProperty: (object: any) => string,
                          inputText: string,
                          stringDistanceFunc: StringDistanceFunc,
                          maxReturnedEntries: number = 3): any|null {

    let closestMatches: DistanceEntry[] = [];
    let distance: number;

    if( !Array.isArray(datasetArray)) {
        return null;
    }

    datasetArray.forEach((item: any, i: number) => {
        const datasetKey = getFieldProperty(item);

        if(datasetKey !== undefined) {
            distance = stringDistanceFunc(datasetKey, inputText);

            // if we have less then "maxReturnedEntries" add match to list
            if (closestMatches.length < maxReturnedEntries) {
                closestMatches.push({ distance: distance, index: i });
            } else {
                // otherwise find if this value distance is better than already in list
                const maxDistanceIndex = closestMatches.findIndex(match => match.distance === Math.max(...closestMatches.map(match => match.distance)));

                // Pokud je aktuální vzdálenost menší než největší vzdálenost v seznamu, nahraďte ji
                if (distance < closestMatches[maxDistanceIndex].distance) {
                    closestMatches[maxDistanceIndex] = { distance: distance, index: i };
                }
            }
        }
    });

    closestMatches = closestMatches.sort(byNumberAscending(item => item.distance));

    // assign all values from entries to return
    closestMatches.forEach(match => {
        const entryDataset = datasetArray[match.index];
        const datasetKey = getFieldProperty(entryDataset);
        if(datasetKey !== undefined) {
            Object.assign(match, entryDataset);
        }
    });

    return closestMatches;
}

export {findEntriesWithLowestDistance};
