import {HeroldFigureBrevno} from "./herold-figures/Brevno";
import {HeroldFigureKlin} from "./herold-figures/Klin";
import {HeroldFigureKul} from "./herold-figures/Kul";
import {findFigureTypeByName} from "./herold-figures/utils";
import {HeroldFigureType} from "../mock-data/herold-figures/HeroldFigureType";
import {HeroldFigureData, IBlazonApiHeroldFigures} from "../api/IBlazonApiHeroldFigures";
import {HeroldFigurePata} from "./herold-figures/Pata";
import {HeroldFigureHlava} from "./herold-figures/Hlava";
import {HeroldFigureSpice} from "./herold-figures/Spice";

/**
 * Represents a mock implementation of the interface for managing herold figures in a blazon.
 */
class BlazonApiHeroldFiguresMock implements IBlazonApiHeroldFigures {

    getMatchHeroldFigure(names: string[], start_x: number, start_y: number, width: number, height: number, tags_attributes: string[], quantity: number): Promise<HeroldFigureData | null> {
        let classSpecialFigure;

        return new Promise((resolve,reject) => {
            const heroldType = findFigureTypeByName(names);

            if(heroldType !== null) {
                switch (heroldType) {
                    case HeroldFigureType.KLIN:
                        classSpecialFigure = HeroldFigureKlin;
                        break;
                    case HeroldFigureType.KUL:
                        classSpecialFigure = HeroldFigureKul;
                        break;
                    case HeroldFigureType.BREVNO:
                        classSpecialFigure = HeroldFigureBrevno;
                        break;
                    case HeroldFigureType.PATA:
                        classSpecialFigure = HeroldFigurePata;
                        break;
                    case HeroldFigureType.PATA_TRAVNIK:
                        classSpecialFigure = HeroldFigurePata;
                        break;
                    case HeroldFigureType.HLAVA:
                        classSpecialFigure = HeroldFigureHlava;
                        break;
                    case HeroldFigureType.SPICE:
                        classSpecialFigure = HeroldFigureSpice;
                        break;
                    default:
                        reject(new Error("Unsupported HeroldFigureType"));
                        return;
                }

                const heroldFigure = new classSpecialFigure(start_x, start_y, width, height, tags_attributes, quantity);

                resolve(heroldFigure.getFigure());
            } else {
                resolve(null);
            }
        });
    }
}

export {BlazonApiHeroldFiguresMock};
